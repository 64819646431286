/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap');

:root {
  /* --font-default: 'Red Hat Display', sans-serif;
  --font-primary: 'Red Hat Display', sans-serif;
  --font-secondary: 'Red Hat Display', sans-serif; */
  --font-default: 'Source Sans Pro', sans-serif;
  --font-primary: 'Source Sans Pro', sans-serif;
  --font-secondary: 'Source Sans Pro', sans-serif;
}

/* Colors */
:root {
    --color-default: #0a0d13;
    --color-primary: #00ad51;
    --color-secondary: #0e1d34;
    --color-logoBlue: #2492eb;
    --color-lightGray: #ebe8e8;
    --color-grayDark: #857b7b;
}

/* skeleton loader css */
@keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

.skeleton_wrpper {
    border: 1px solid #c5c5c5;
}

.skel_heading {
    width: 250px;
    height: 45px;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}
.skel_profile_min {
    width: 150px;
    height: 24px;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}
.skel_profile_max {
    width: 180px;
    height: 24px;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}
.skel_cards {
    border-radius: 10px;
    max-width: 290px;
    overflow: hidden;
    /* border: 1px solid #c5c5c5; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.skel_counters h2 {
    width: 40px;
    margin: 0;
    height: 25px;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}
.skel_card_desc p {
    margin: auto;
    width: 100%;
    height: 80px;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}
.skel_card_offer p {
    margin: auto;
    width: 100%;
    height: 25px;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}
.skel_card_img {
    border-radius: 10px;
    height: 150px;
    overflow: hidden;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}