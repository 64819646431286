/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap');

:root {
  /* --font-default: 'Red Hat Display', sans-serif;
  --font-primary: 'Red Hat Display', sans-serif;
  --font-secondary: 'Red Hat Display', sans-serif; */
  --font-default: 'Source Sans Pro', sans-serif;
  --font-primary: 'Source Sans Pro', sans-serif;
  --font-secondary: 'Source Sans Pro', sans-serif;
}

/* Colors */
:root {
  --color-default: #0a0d13;
  --color-primary: #00ad51;
  --color-secondary: #0e1d34;
  --color-logoBlue: #2492eb;
  --color-lightGray: #ebe8e8;
  --color-grayDark: #857b7b;
}


:root {
  --font-default: 'Red Hat Display', sans-serif;
  --font-primary: 'Red Hat Display', sans-serif;
  --font-secondary: 'Red Hat Display', sans-serif;
}

/* Colors */
:root {
  --color-default: #0a0d13;
  --color-primary: #00ad51;
  --color-secondary: #0e1d34;
  --color-logoBlue: #2492eb;
  --color-lightGray: #ebebeb;
}


.twenty-one {
  /* background: #fff; */
  color: #fff;
  border: none;
  /* text-transform: uppercase; */
  position: relative;
  z-index: 1;
  /* transition: color 400ms; */
}

.twenty-one::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: -1;
  transition: transform 400ms ease-out;
  transform: scaleX(0);
  transform-origin: left;
}

.twenty-one:hover::before {
  transform: scaleX(1);
}

.twenty-one:hover {
  color: var(--color-default) !important;
  border-color: #fff !important;
  font-weight: 700;
}

.twenty-one:hover {
  color: #fff;
}

.details-btn {
  /* background: #fff; */
  color: #fff;
  border: none;
  /* text-transform: uppercase; */
  position: relative;
  z-index: 1;
  transition: color 400ms;
}

.details-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-primary);
  z-index: -1;
  transition: transform 400ms ease-out;
  transform: scaleX(0);
  transform-origin: left;
}

.details-btn:hover::before {
  transform: scaleX(1);
}

.details-btn:hover {
  color: #fff;
}

.list-bottom {
  bottom: -114px;
}

/* otp button */
.otp-btn {
  border: none;
  outline: none;
  font-size: 14px;
  background-color: var(--color-primary);
  color: white;
  padding: 6px 10px;
  position: absolute;
  right: 6px;
  top: 9px;
  border-radius: 18px;
  /* box-shadow: 0 0 2px rgba(0,0,0,2); */
  cursor: pointer;
}

.btn_formbyscan {
  top: 4px !important;
}

.otp-btn:hover {
  background-color: var(--color-logoBlue);
  color: white;
}

/* Completed button */
.completed {
  color: black !important;
  background: #f1f1f1 !important;
  border: none !important;
  font-weight: 600 !important;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.5) !important;
  cursor: default !important;
}

.completed:hover {
  color: black !important;
  background: #f1f1f1 !important;
  border: none !important;
  font-weight: 600 !important;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.5) !important;
  cursor: default !important;
}

.completed::before,
.completed::after {
  background: #f1f1f1 !important;
}

/* css loader */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--color-logoBlue);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

/* ===============Animated arrow */

@keyframes blob-1-anim {
  0% {
    transform: translateX(-50%) translateY(0);
  }

  14% {
    transform: translateX(-50%) translateY(-8px);
  }

  24% {
    transform: translateX(-50%) translateY(0);
  }

  100% {
    transform: translateX(-50%) translateY(0);
  }
}

.animated_arrow {
  animation: blob-1-anim 3s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

