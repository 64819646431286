/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap");

:root {
   /* --font-default: 'Red Hat Display', sans-serif;
  --font-primary: 'Red Hat Display', sans-serif;
  --font-secondary: 'Red Hat Display', sans-serif; */
   --font-default: "Source Sans Pro", sans-serif;
   --font-primary: "Source Sans Pro", sans-serif;
   --font-secondary: "Source Sans Pro", sans-serif;
}

/* Colors */
:root {
   --color-default: #0a0d13;
   --color-primary: #00ad51;
   --color-secondary: #0e1d34;
   --color-logoBlue: #2492eb;
   --color-lightGray: #ebe8e8;
   --color-grayDark: #857b7b;
}

.slider-container .cards_item {
   display: flex;
   padding: 1rem;
}

.card {
   /* background-color: white; */
   border-radius: 20px;
   box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
   display: flex;
   flex-direction: column;
   overflow: hidden;
   border: none;
}

.card_content {
   padding: 12px;
   background-color: #fff;
   /* background: linear-gradient(to bottom left, #EF8D9C 40%, #FFC39E 100%); */
}

.card_title {
   color: var(--color-default);
   font-size: 16px;
   font-weight: 700;
   letter-spacing: 1px;
   text-transform: capitalize;
   margin: 0px;
   margin-bottom: 5px;
   height: 38px;
   display: -webkit-box;
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
}

.dash_card_link {
   display: flex;
   justify-content: center;
   align-items: center;
}

/* .dash_card_w .card_title {
    height: 38px !important;
} */

.card_text {
   color: #6c757d;
   font-size: 16px;
   line-height: 1.5;
   margin-bottom: 1.25rem;
   font-weight: 400;
   display: -webkit-box;
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
}

.para_overflow_cont {
   min-height: 48px;
   max-height: 48px;
   overflow: hidden;
}

/* @media screen and (min-width: 992px) {
    .para_overflow_cont{
        min-height: 72px;
        max-height: 72px;
    }
} */
/* @media screen and (max-width: 1025px) {
    .para_overflow_cont{
        min-height: 72px !important;
        max-height: 72px !important;
    }
} */

.dash_slider_content_card .para_overflow_cont {
   min-height: 48px;
   max-height: 48px;
   overflow: hidden;
}

/* .dash_slider_content_card .card {
    width : 360px !important;
} */
.dash_slider_content_card .card_item {
   padding: 15px 35px !important;
}

.dash_slider_content_card .card_title {
   margin-bottom: 5px !important;
}

/* dash card width */
.dash_card_w {
   width: 350px !important;
}

.slick-next:before {
   content: "\f061" !important;
   font-family: "Font Awesome 5 Free" !important;
   font-weight: 900 !important;
   background-color: #000 !important;
   color: white !important;
   font-size: 20px !important;
   padding: 8px !important;
   border-radius: 50% !important;
}

.slick-prev:before {
   content: "\f060" !important;
   font-family: "Font Awesome 5 Free" !important;
   font-weight: 900 !important;
   background-color: #000 !important;
   color: white !important;
   font-size: 20px !important;
   padding: 8px !important;
   border-radius: 50% !important;
}

.dash_slider_content_card .slick-next {
   right: 0px;
}

.dash_slider_content_card .slick-prev {
   left: -12px;
   /* left: -21px; */
}

@media screen and (min-width: 600px) {
   /* .dash_slider_content_card {
        padding: 0 20px;
    } */
   .dash_slider_content_card .slick-slider {
      padding: 0 15px;
   }
}

/* product_card */
.xpand_card .details-card {
   max-width: 318px !important;
}

.pro_del_card .card {
   max-width: 318px !important;
}

@media screen and (min-width: 1289px) {
   /* .details-card {
        width: 318px !important;
    } */
   .col_details {
      display: flex;
      justify-content: center;
   }
}

.made_by {
   font-weight: 400;
   font-size: 13px;
   margin-top: 35px;
   text-align: center;
}

.doc-img {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 10px 35px;
}

.pdfLink,
.videoLink {
   color: var(--color-default);
}

.pdf-img,
.video-img {
   position: relative;
}

.pdf-counter,
.video-counter {
   position: absolute;
   bottom: -12px;
   right: -9px;
   background-color: var(--color-primary);
   text-align: center;
   color: #fff;
   padding: 3px;
   font-size: 12px;
   height: 18px;
   width: 18px;
   line-height: 1;
   border-radius: 50%;
}

.card_image img {
   width: 100%;
   height: 210px;
}

.doc-img img {
   width: 25px;
}

.offer_cont {
   margin-top: 18px;
   background-color: var(--color-lightGray);
   padding: 5px 20px;
   /* border-radius: 5px; */
   overflow: hidden;
}

.offer_cont i {
   color: var(--color-logoBlue);
}

.offer_text {
   color: var(--color-default);
}

/* video thumbnail card */

.video-card {
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
   padding: 20px 15px;
}

.video-card-grid:nth-child(even) .video-card {
   /* display: flex; */
   background: #9982f4;
   border-radius: 15px;
}

.video-card-grid:nth-child(odd) .video-card {
   /* display: flex; */
   background: #f28566;
   border-radius: 15px;
}

.video-thumb {
   text-align: center;
}

.video-thumb img {
   width: 120px;
   height: 120px;
   border-radius: 50%;
}

.vid_card_content h2 {
   font-size: 18px;
   /* color: var(--color-default); */
   color: #fff;
   margin-top: 5px;
   margin-bottom: 10px;
   text-align: center;
   display: -webkit-box;
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   /* height: 40px; */
}

.vid_card_content p {
   font-size: 16px;
   color: #f5f5f5;
   text-align: justify;
   display: -webkit-box;
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
}

.vid_card_content .btn-container .btn {
   background: #fff;
   color: var(--color-default);
   border-radius: 20px;
   /* padding: 5px; */
   width: 48%;
   box-shadow: 0 0 5px rgba(0, 0, 0, 0.212);
   transition: 0.3s;
   overflow: hidden;
   font-size: 14px;
}

.vid_card_content .btn-container .btn:hover {
   background-color: #15d16d;
   color: white;
}

.offer_cont {
   position: relative;
}

.offer_cont .offer_text {
   position: inherit;
   font-size: 14px;
}

.offer_cont::before {
   content: "";
   position: absolute;
   width: 0;
   height: 100%;
   background-color: white;
   border-radius: 2px;
   left: 0;
   border: 1px solid var(--color-logoBlue);
   top: 0;
   transition: 0.3s;
}

.offer_cont:hover:before {
   width: 100%;
}

/* dashboard */
.sec-title_list h2 {
   font-size: 20px;
   font-weight: 600;
   margin-bottom: 5px;
}

.listing_main_p {
   color: var(--color-default);
   font-size: 16px;
}

a.see_more {
   color: var(--color-logoBlue);
   cursor: pointer;
   font-size: 14px;
}

a.see_more:hover {
   color: var(--color-primary);
}

/* ######### Profile styling ################# */
/* common styles for profile page */
.heading-text-container {
   max-height: 50px;
   padding: 23px;
}
.user-details-section {
   padding: 15px 20px;
}

.heading-text-container h4 {
   padding: 0 8px;
   margin: 0;
   font-size: 1.2rem;
   font-weight: 600;
   color: black;
}

/*  QR Code Section  */

.ternary-wrapper {
   padding: 18px;
}
.ternary-wrapper .qr-code {
   max-width: 100px;
   max-height: 100px;
   /* margin-left: 5px; */
}
/* QR Code Download Button */
.download-qr-btn {
   color: whitesmoke;
   background-color: #08554e;
   font-size: 10px;
   margin-top: 5px;
}

.profile_pic_wrap img {
   width: 120px;
   height: 120px;
   border-radius: 50%;
}

.profile_social_icons ul {
   display: flex;
   list-style: none;
   align-items: center;
   justify-content: center;
   padding: 0;
   margin: 0;
}

.profile_social_icons ul li a {
   display: inline-block;
   margin-right: 6px;
}

.profile_contact_details span {
   /* margin-left: 20px; */
   word-break: break-all;
   font-size: 15px;
   font-weight: 500;
}

.profile_contact_details .contact_rows {
   display: flex;
   align-items: center;
   margin-bottom: 7px;
   padding: 3px;
}

.profile_contact_details .contact_rows .det_sep {
   min-width: 66px;
   font-weight: 600;
   color: var(--color-default);
}

.profile_contact_details .contact_rows .det_sep.long-key {
   min-width: 110px !important;
}

.profile_contact_details .contact_rows .details_span {
   color: black;
   margin-left: 10px;
}

.profile_wrapper {
   background-color: #cbdee76e;
   border-radius: 8px;
}

.profile_contact_details.user_details {
   padding: 15px 20px;
}

.profile_wrapper .profile_detail {
   padding: 16px;
}

.dp_sec_cont .rm_head {
   padding: 27.9px;
   border-bottom: 1.5px solid #fff;
   justify-content: space-between;
}

.download-qr-btn:hover {
   color: whitesmoke;
   background-color: #0ea89a;
   box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.download-qr {
   display: flex;
   border: none;
   right: 0;
   background-color: #ff1972;
   padding: 8px;
   font-size: 12px;
   color: white;
   border-radius: 50%;
   cursor: pointer;
}
.download-qr:hover {
   background-color: #f53d84;
   color: white;
   box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.download-qr i:active {
   transform: scale(0.9);
}
.sub_documnet {
   display: flex;
   border: none;
   background-color: #08554e;
   padding: 8px 25px;
   font-size: 15px;
   color: white;
   border-radius: 5px;
   margin-bottom: 10px;
   margin: auto;
   cursor: pointer;
}

.document-wrapper {
   background-color: #cbdee76e;
   border-radius: 8px;
}
.document-wrapper .task_header {
   padding: 19.5px;
}
.document-wrapper .col-task-header {
   border-bottom: 1.5px solid #fff;
}
.profile_wrapper .col-img-profile {
   border-left: 1.5px solid #fff;
}

.profile_wrapper .col-task-header {
   padding: 22.9px;
   padding-left: 20px;
   border-bottom: 1.5px solid #fff;
}
.doc_header {
   border-bottom: 1.5px solid #fff;
}
.rm_head h3 {
   font-size: 17px;
   font-weight: 600;
   margin: 0;
}
.document-wrapper .det_sep {
   min-width: 120px;
   margin-right: 5px;
}
.complete-pro .contact_rows {
   display: flex;
   margin-top: 10px;
   margin-left: 12px;
}
.complete-pro .contact_rows input {
   line-height: 1.5;
   margin-left: 5px;
}
.document-wrapper .complete-pro .contact_rows .det_sep {
   min-width: 180px;
   font-weight: 500;
   font-size: 18px;
}
.profile_contact_details {
   /* border: 1px solid var(--color-lightGray); */
   border-radius: 8px;
   /* width: fit-content; */
}
.submit_details {
   display: flex;
   justify-content: flex-end;
   margin-bottom: 20px;
}
.submit_details {
   margin-top: 38px;
}
.submit_details button {
   background-color: #08554e;
   padding: 11px 50px;
   width: 100%;
   font-size: 14px;
   color: white;
   border-radius: 5px;
   border: none;
}
.submit_details button:hover {
   background-color: #0ea89a;
   color: white;
}
.upload-file input[type="file"] {
   display: none;
}
.upload-file label {
   /* display: inline-block;
   font-size: 15px;
   cursor: pointer;
   background-color: #08554e;
   color: #fff;
   padding: 6px 14px;
   border-radius: 25px; */

   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   gap: 5px;
   justify-content: center;
   align-items: center;
   border: 2px solid #08554e;
   border-radius: 5px;
   cursor: pointer;
   /* background-color: #fff; */
}
.upload-file > label {
   margin-left: 15px;
}
.upload-file > label > div {
   /* font-weight: 600; */
   color: #08554e;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: center;
   align-items: center;
   padding: 7px 15px;
   font-size: 0.9rem;
}
.upload-small-container > div > i {
   padding: 0 5px 0 0;
}
.upload-small-container > div > p {
   font-weight: 500;
}

.upload-file label:active {
   transform: scale(0.9);
}
.upload-file input[type="file"]:disabled + label {
   background-color: #605f5f;
   cursor: default;
}
.pdf_down {
   background-color: #ff1972;
   padding: 6px 9px;
   font-size: 14px;
   color: white;
   border-radius: 50%;
   cursor: pointer;
}
.pdf_down:hover {
   background-color: #f53d84;
   color: white;
   box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.pdf_down i:active {
   transform: scale(0.9);
}
table td img {
   width: 17vw;
   height: 16vh;
}

.popup-container {
   display: flex;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.8);
   z-index: 999;
   justify-content: center;
   align-items: center;
}

.popup-image {
   width: 28vw;
   height: 45vh;
}
.table th {
   font-size: 14px;
   font-weight: 600;
}
.close {
   position: absolute;
   top: 20%;
   right: 30%;
   font-size: 30px;
   font-weight: 600;
   color: white;
   cursor: pointer;
}
.profile_wrapper .profile_tag {
   width: fit-content;
   display: inline-block;
   background-color: #c1abce;
   padding: 5px 12px;
   color: white;
   font-size: 14px;
   font-weight: 500;
   border-radius: 8px;
   margin-top: 5px;
}

.profile_detail p {
   color: var(--color-grayDark);
}

.profile_social_icons a {
   display: block;
   text-align: center;
   width: 35px;
   height: 35px;
   border-radius: 50%;
   background-color: var(--color-lightGray);
   padding: 5px;
   line-height: 2;
}

.profile_social_icons i {
   font-size: 22px;
   color: gray;
   transition: all 0.3s;
}

.profile_header h3 {
   font-size: 18px;
   font-weight: 600;
}

.edit_profie_btn {
   background-color: #ff1972;
   /* padding: 8px 10px; */
   font-size: 12px;
   font-weight: 400;
   color: white;
   border-radius: 20px;
}

.chnge_pass {
   background-color: #914d67;
}

.chnge_pass:hover {
   background-color: #c54b79 !important;
}

.edit_profie_btn:hover {
   background-color: #f53d84;
   color: white;
   box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.bttn__profile {
   background-color: #ed6755;
   border: none;
   border-radius: 5px;
   width: auto !important;
   padding: 7px 15px !important;
   font-size: 16px;
   color: white;
   cursor: pointer;
   box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
}
.complete-pro .mb-3 label {
   font-size: 18px;
   font-weight: 500;
   color: var(--color-default);
}
.profile_contact_details img {
   margin: 5px;
   width: 8vw;
}
.table > :not(caption) > * > * {
   border-bottom-width: 0;
}
.table tr td:first-of-type {
   color: gray;
}
/* popups form */
.form_popups {
   position: fixed;
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 10;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.9);
   overflow: hidden;
   z-index: 999;
}

.popup_close_icon {
   background-color: #c5c5c5;
   color: var(--color-default);
   border-radius: 50%;
   padding: 10px;
   width: 30px;
   height: 30px;
   line-height: 1;
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   right: -5px;
   top: -5px;
   font-size: 20px;
   cursor: pointer;
}

.popup_close_icon.createLead {
   right: 0;
}

/* product details section */
.courses-desc h2 {
   font-size: 20px;
   font-weight: 600;
}

/* dash iframes */
.testi-video-inner-2 iframe,
.videoBoxes iframe {
   border-radius: 15px !important;
}

/* banner slider slick */
.bannerSlider {
   min-height: 150px;
}

.bannerSlider .slick-prev {
   left: 0 !important;
}

.bannerSlider .slick-next {
   right: 18px !important;
}

.bannerSlider .slick-dots {
   bottom: 20px !important;
}

/* dasboard complete profile form styling */
.profile_complete_dash_form {
   width: 100%;
   padding: 0;
   border-radius: 0px;
   overflow: hidden;
   background-color: unset;
   box-shadow: unset;
   transition: unset;
}

.profile_complete_dash_form input,
.profile_complete_dash_form select {
   height: 50px !important;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
   background-color: #fff !important;
}

.profile_complete_dash_form .InptLabels {
   color: #605f5f;
   font-size: 16px;
   /* text-shadow: 10px 10px 0 rgba(0, 0, 0, 0.212); */
   font-weight: 600;
   margin-bottom: 10px;
}

.profile_complete_dash_form .form-group {
   padding: 0 18px;
}

.profile_complete_dash_form .search-wrapper {
   position: relative;
}

.profile_complete_dash_form .optionListContainer {
   position: absolute;
   left: 0;
}

.profile_complete_dash_form .optionListContainer ul {
   max-height: 130px !important;
   max-width: 100%;
}

/* business testimonials */
/* ==================== NOTICE BOARD=========================== */
.border_box {
   border: 1px solid rgb(187, 185, 185);
   padding: 8px;
   border-radius: 10px;
   height: 100%;
}

.notice_container {
   overflow: hidden;
   position: relative;
   box-sizing: border-box;
   /* max-height: 420px; */
   min-height: 150px;
   max-height: 180px;
}

.notice__board h4 {
   font-size: 15px;
   background-color: var(--color-lightGray);
   font-weight: 400;
   padding: 4px;
}

.testi__notice .notice__board h4 {
   background: #0ea89a !important;
   color: #fff !important;
}

.events_container {
   background-color: #fff;
   padding: 5px;
   border-radius: 5px;
   box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.eneventLinks {
   display: block;
   margin-bottom: 8px;
}

.events_container .event_topic {
   color: #000;
   font-size: 15px;
   font-weight: 400;
   display: -webkit-box;
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
}

.events_container .date__time {
   /* background-color: var(--color-lightGray); */
   color: var(--color-default);
   padding: 2px;
   font-size: 13px;
   width: fit-content;
   border-radius: 5px;
}

.joinBtn {
   background-color: #ff1972;
   padding: 3px 6px;
   font-size: 13px;
   color: white;
   border-radius: 20px;
}

.joinBtn:hover {
   color: #000;
}
.checkin-timer {
   margin-right: 10px;
   font-weight: 500;
}
.checkin-disabled {
   cursor: not-allowed;
   opacity: 0.8;
}
.register-dropdown{
   background-color: #f0f4f5;
   position: absolute;
   width: 78%;
   box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.2);
   cursor: pointer;
   padding: 0;
   border-radius: 5px;
}
.register-dropdown li{
   list-style: none;
   padding: 8px 15px;
}
.register-dropdown hr{
   margin: 0;
   margin-top: 5px;
}
.business-plan{
   background-color: #f0f4f5;
   box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.2);
   padding: 10px;
   border-radius: 5px;
}
.create-business{
   display: flex;
   flex-direction: column;
}
.create-business input{
   height: 32px;
}
@media screen and (min-width: 992px) {
   .notice_container.adjustHeight {
      max-height: 500px !important;
   }
}

@media screen and (max-width: 991px) {
   .notice_container.adjustHeight {
      max-height: 350px !important;
   }
   .px-4 {
      padding-right: 0 !important;
      padding-left: 0 !important;
   }
}

@media screen and (max-width: 1245px) {
   .notice_container {
      max-height: 200px;
      /* max-height: 325px; */
   }
}

@media screen and (max-width: 880px) {
   .joinBtn {
      padding: 2px;
   }
}

@media screen and (max-width: 768px) {
   .joinBtn {
      padding: 5px 8px;
   }

   .notice_container {
      max-height: 300px;
      /* max-height: 325px; */
   }
   .profile_wrapper .col-img-profile {
      border-top: 1.5px solid #fff;
   }
   .profile_contact_details img {
      width: 24vw;
   }
   .sub_documnet {
      margin: auto;
      margin-bottom: 10px;
   }
}
@media (min-width: 768px) {
   .profile_wrapper .col-md-8 {
      width: 60.66666667%;
   }
   .profile_wrapper .col-md-4 {
      flex: 0 0 auto;
      width: 39.33333333%;
   }
}

.notice_container ul {
   padding: 0;
   margin: 0;
}

.notice_container ul li {
   position: relative;
   list-style: none;
   padding-left: 10px;
}

.notice_container ul li::before {
   content: "\261E";
   font-size: 28px;
   font-weight: 500;
   color: var(--color-primary);
   position: absolute;
   left: -20px;
}

.newsPara {
   padding: 0 1em;
   line-height: 1.5em;
}

.notice_container .marquee {
   top: 6em;
   position: relative;
   box-sizing: border-box;
   will-change: transform;
   animation: marquee_2 15s linear infinite;
}

.notice_container .marquee:hover {
   animation-play-state: paused;
}

/* Make it move! */
@keyframes marquee_2 {
   0% {
      transform: translate(0, 0);
   }

   100% {
      transform: translate(0, -100%);
   }
}

@keyframes marquee {
   0% {
      top: 9em;
   }

   100% {
      top: 0em;
   }
}

/* Make it look pretty */
.notice_container .marquee {
   margin: 0;
   padding: 0 1em;
   line-height: 1.5em;
}

.notice_container:before,
.notice_container::before,
.notice_container:after,
.notice_container::after {
   left: 0;
   z-index: 1;
   content: "";
   position: absolute;
   pointer-events: none;
   width: 100%;
   height: 2em;
   /* background-image: linear-gradient(top, #FFF, rgba(255,255,255,0)); */
}

.notice_container:after,
.notice_container::after {
   bottom: 0;
   transform: rotate(180deg);
}

.notice_container:before,
.notice_container::before {
   top: 0;
}

/* =========Raised query desing================= */
.raised_query_wrapper {
   background-color: #fff;
   /* padding: 15px; */
   border-radius: 8px;
   width: 500px;
}

@media screen and (max-width: 550px) {
   .raised_query_wrapper {
      width: 320px;
   }
   .popup-image {
      width: 60vw;
      height: 45vh;
   }
   .close {
      top: 20%;
      right: 20%;
   }
}

.raised_query_wrapper textarea {
   border-radius: 8px;
   padding: 10px;
}

.raised_query_wrapper .subBtn {
   background-color: #ed6755;
   color: white;
   box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
}

/* ===============BOOk APPointment+============= */
.book__appointment .input,
.book__appointment .select__control,
.book__appointment textarea {
   background: #eee;
   border: 1px solid #ced4da !important;
}

.book__appointment .input,
.book__appointment select {
   height: 40px;
}

.book__appointment select {
   color: #6c757d;
   border: 1px solid #ced4da !important;
}

.book__appointment label {
   margin-bottom: 2px;
}

.book__appointment .css-qbdosj-Input {
   padding-left: 0px !important;
}

.book__appointment .css-1jqq78o-placeholder {
   margin-left: 0px !important;
}

.select__multi-value__label {
   color: #fff !important;
}

.css-12a83d4-MultiValueRemove {
   border-radius: 0 8px 8px 0 !important;
}

.css-1p3m7a8-multiValue {
   background-color: var(--color-logoBlue) !important;
   color: #fff !important;
   border-radius: 10px !important;
   overflow: hidden;
}

/* =============date time picker input========== */
.dateTimePicker input {
   margin-bottom: 0 !important;
   /* padding: 0 5px !important; */
   padding: 0 !important;
}

.dateTimePicker input:focus,
.dateTimePicker select:focus {
   border: none !important;
   outline: none !important;
}

.dateTimePicker select {
   padding: 0 !important;
   border: none !important;
   width: fit-content;
}

.dateTimePicker .react-datetime-picker__wrapper {
   border: none !important;
   padding-left: 8px;
   height: 40px;
}

.dateTimePicker .react-datetime-picker {
   border: 1px solid #ced4da !important;
   border-radius: 8px;
   width: 100%;
   background: #eee;
}

.dateTimePicker .react-datetime-picker__inputGroup__input:invalid {
   background-color: unset;
}

.dateTimePicker .react-datetime-picker__inputGroup__leadingZero {
   width: 20px;
   margin-top: 1px;
   color: var(--color-grayDark) !important;
}

.dateTimePicker .react-datetime-picker__inputGroup__year {
   padding-right: 5px !important;
}

.dateTimePicker .react-datetime-picker__inputGroup__divider {
   color: var(--color-grayDark);
   margin-top: 2px;
}

/* responsive popup book aapointment */
.responsivePopup {
   max-height: 545px;
   overflow: hidden;
   overflow-y: auto;
   padding-top: 10px;
}

/* ===============Discussion Forum styling============ */
.chatPopup {
   position: fixed;
   top: 50%;
   left: 50%;
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   transform: translate(-50%, -50%);
}

.chatBgoverlay {
   position: absolute;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.7);
}

/* 
.chat-box section::-webkit-scrollbar {
    width: 10px;
}

.chat-box section::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.chat-box section::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
} */

.chat-box {
   width: 300px;
   height: 400px;
   background-color: #eee;
   border-radius: 16px 16px 0 0;
   /* overflow: hidden; */
   border: 2px #45f solid;
   z-index: 99999999;
}

.chat-box header {
   background-color: #45f;
   color: #fff;
   display: flex;
   padding: 0 16px;
   border-radius: 16px 16px 0 0;
}

.chat-box header > * {
   line-height: 40px;
}

.chat-box header h1 {
   margin: 0;
   font-size: 1.1em;
}

.chat-box header .close-button {
   cursor: pointer;
   user-select: none;
}

.chat-box section {
   overflow-y: scroll;
   overflow-x: hidden;
   height: calc(100% - 40px - 40px);
   
}

.chat-box section div {
   margin: 12px 0;
}

.chat-box section span {
   display: inline-block;
   padding: 6px 8px;
   margin: 1px 0;
   width: 85%;
   word-wrap: break-word;
}
.chat-box section span p{
   margin-bottom: 0;
   font-size: 10px;
}
.chat-box section div.me {
   text-align: right;
   
}

.chat-box section div.me span {
   background-color: #68c;
   border-radius: 4px 16px 16px 4px;
}

.chat-box section div.me span:first-of-type {
   border-top-left-radius: 16px;
}

.chat-box section div.me span:last-of-type {
   border-bottom-left-radius: 16px;
}

.chat-box section div.not-me {
   text-align: left;
  
}
.chat-box section div.not-me span p{
   text-align: right;
}
.chat-box section div.not-me span{
   text-align: left;
}
.chat-box section div.me span{
   text-align: left;
   font-size: 18px;
}
.chat-box section div.me span p{
   text-align: right;
}
.chat-box section div.not-me span {
   background-color: #bcf;
   border-radius: 16px 4px 4px 16px;
}

.chat-box section div.not-me span:first-of-type {
   border-top-right-radius: 16px;
}

.chat-box section div.not-me span:last-of-type {
   border-bottom-right-radius: 16px;
}

.chat-box footer input[type="text"] {
   font-family: var(--font-default);
   outline: 0;
   padding: 0 12px;
   height: 40px;
   width: 100%;
   font-size: 1em;
   border: none;
   background: transparent;
}
.chat-box footer input[type="checkbox"]{
   height: 15px;
   width: 15px;
}
.chat-check-box{
   background-color: rgb(255, 255, 255);
   border: 1.7px #45f solid;
   border-top: none;
   width: 299.5px;
   margin-left: -2px;
   padding: 3px 4px;
}
/* ===================Register now agreement veiwer========================= */
.cp__regNow_form {
   height: unset;
   /* height: auto; */
}
#Iframe-Master-CC-and-Rs {
   max-width: 44%;
   max-height: 100%;
   overflow: hidden;
}

/* inner wrapper: make responsive */
.responsive-wrapper {
   position: relative;
   height: 0;
   /* gets height from padding-bottom */
   -webkit-overflow-scrolling: touch;
   overflow: hidden;
}

.responsive-wrapper iframe {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 80vh;
   margin: 0;
   padding: 0;
   overflow: hidden;
   border: none;
}

/* padding-bottom = h/w as % -- sets aspect ratio */
/* YouTube video aspect ratio */
.responsive-wrapper-wxh-572x612 {
   height: 75vh;
   background: var(--color-lightGray);
   overflow: hidden;
}

.center-block-horiz {
   margin-left: auto !important;
   margin-right: auto !important;
}

/* .pdf__viewer__slide {
    height: 80vh;
  } */
.agreement__view {
   border-top: 2px solid gray;
   padding: 10px 0;
   border-radius: 0 0 10px 10px;
   display: flex;
   justify-content: space-around;
}
.agreement__view button,
.spinner_style {
   padding: 5px 35px;
   background-color: #ed6755;
   color: white;
   box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
   border: none;
   border-radius: 8px;
   padding: 8px 25px;
}
.agreement__view button:hover {
   background-color: #ed6755;
   color: white;
   box-shadow: 0px 8px 15px rgba(237, 103, 85, 1);
}
.agreement__doc__wrap {
   height: 290px;
   position: absolute;
   bottom: 0px;
   width: 100%;
   z-index: 999999;
   left: 0px;
   overflow: hidden;
   transform: translateY(0%);
   visibility: visible;
   opacity: 1;
   transition: all 0.3s;
   -webkit-box-shadow: 0px -7px 10px #0000005b;
   -moz-box-shadow: 0px -7px 10px #0000005b;
   box-shadow: 0px -7px 10px #0000005b;
   border-radius: 10px 10px 0 0;
}
.doc__input_wrap {
   height: 100%;
   background-color: #fff;
   padding: 0 30px;
}
.doc__input_wrap .opt_input {
   overflow: hidden;
   border-radius: 8px;
   box-shadow: 8px 10px 25px rgba(0, 0, 0, 0.3);
}
.doc__input_wrap .opt_input input {
   height: 45px;
   border: none;
   border-radius: 8px;
   padding-left: 10px;
   background-color: var(--color-lightGray);
}
.doc__input_wrap .opt_input input:focus {
   outline-color: var(--color-logoBlue);
}
.doc__input_wrap .opt_input .fileIcon {
   cursor: pointer;
   padding: 8px 20px;
   border-radius: 0 8px 8px 0;
}
.registerNowSlide {
   transform: translateY(24%);
}
.pdfHide {
   visibility: hidden;
   opacity: 0;
}
.otpVerifyHide {
   transform: translateY(130%);
}
.moreTabAgreeent {
   transform: translateY(-26%);
   transition: all 0.3s;
}
.moreTabAgreeen .register_form__container {
   transition: all 0.3s;
   visibility: hidden;
   opacity: 0;
}
.pdf-page {
   max-width: 100%;
   height: 100%;
   overflow: auto;
}
.pdf-page .page {
   background-color: #fff;
   max-width: 100%;
   min-height: 100vh;
   page-break-after: always;
   padding: 20px;
   text-align: justify;
}
.pdf-page .page .pdf-logo {
   width: 60px;
   height: 50px;
   margin-bottom: 30px;
}
.pdf-page .page .pdf-logo img {
   width: 100%;
   height: 100%;
   background-size: contain;
}

.pdf-page .page p,
.pdf-page .page strong {
   font-size: 12.5px;
   margin-left: 15px;
   margin-right: 15px;
   letter-spacing: 1px;
}
.pdf-page .page p {
   font-weight: 500;
}
.pdf-page .page p span {
   letter-spacing: 1px;
   font-size: 13px;
   margin-right: 4px;
   margin-left: 4px;
}
.pdf-page .page .para-column {
   display: flex;
   flex-direction: column;
}
.pdf-page .page .d-flex {
   justify-content: space-between;
   margin-top: 15rem;
}
.pdf-page .page .d-flex .img-box {
   display: flex;
   flex-direction: column;
   width: 100%;
   text-align: center;
}
.pdf-page .page .d-flex .img-box img {
   width: 120px;
   height: 50px;
   margin: auto;
}
.contract-card {
   background-color: #fff;
   height: 250px;
   bottom: 20px;
   width: 100%;
   z-index: 999999;
   text-align: center;
   overflow: hidden;
   transform: translateY(0%);
   visibility: visible;
   opacity: 1;
   transition: all 0.3s;
   box-shadow: 0px -7px 10px #0000005b;
   border-radius: 10px 10px 0 0;
   padding: 20px;
}
.contract-view {
   transform: translateY(100%);
}
.contract-card p {
   text-align: center;
   width: 80%;
   margin: auto;
}
.contract-card .view-contract {
   background-color: #ed6755;
   color: white;
   box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
   border: none;
   border-radius: 8px;
   padding: 8px 25px;
   margin-top: 5px;
}
.terms-condition {
   justify-content: flex-start;
   align-items: center;
}
.terms-condition label {
   width: 100%;
   margin-left: 15px;
}
.terms-condition input[type="checkbox"] {
   width: 20px;
}
.send-query {
   margin-right: 10px;
   border: none;
   background: transparent;
   font-size: 1.2rem;
   color: #45f;
}
.download-pdf {
   float: right;
   border: none;
   outline: none;
   font-size: 1.2rem;
}
.hidden-bookAppointment {
   display: none;
}
.registration-status {
   position: absolute;
   top: 5%;
   left: 40%;
   width: 30vw;
   height: 30vh;
   z-index: 999;
   background-color: #ffffff;
   border-radius: 5px;
}
.registration-status span {
   position: absolute;
   right: 0;
   top: 0;
   background-color: #c5c5c5;
   color: var(--color-default);
   padding: 0.5px 6px;
   border-radius: 50%;
   cursor: pointer;
   font-weight: 600;
}
.com-pro-alert {
   width: auto;
   position: absolute;
   top: 4%;
   z-index: 9999;
   display: flex;
   right: 0;
}
.alert.alert-danger {
   display: flex;
   width: 100%;
   height: 60px;
}
.alert.alert-danger a {
   text-decoration: underline;
   margin-left: 10px;
   margin-right: 20px;
}
.com-pro-alert .alert-cancel {
   position: absolute;
   display: block;
   right: 5px;
   top: 17px;
}
.fa-triangle-exclamation {
   margin-right: 10px;
   font-size: 20px;
}
.fa-caret-down,
.fa-caret-up {
   float: right;

   font-size: 26px !important;
}
.generate_support {
   border: none;
   background-color: #08554e;
   color: #fff;
   border-radius: 5px;
   padding: 5px 15px;
   font-size: 14px;
}
/*  */
#sidebar_nav_toggle > i {
   font-size: 20px;
}
.tool-tip {
   width: 26vw;
   height: 33vh;
   background: #fff;
   border-radius: 10px;
   border-top: 4px solid #00ad51;
   margin-top: -40px;
   overflow-y: scroll;
   box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238);
}
.tool-tip table {
   width: 100%;
   margin-top: 10px;
}
.tool-tip table tr {
   width: 100%;
   display: flex;
   justify-content: space-between;
   padding: 5px;
}
.tool-tip table tr td {
   padding: 5px 0;
}
.tool-tip table tr:nth-child(even) {
   background-color: #eee;
}
.tool-tip .fa-solid.fa-xmark {
   position: absolute;
   font-size: 24px;
   margin-top: -12px !important;
   margin-left: -12px;
   cursor: pointer;
   background-color: #eee;
   border-radius: 50%;
   padding: 0 4px;
}
.query_answer {
   width: 100%;
   height: 42vh !important;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   padding: 0;
}
.unanswered,
.answered {
   height: 50%;
   padding: 10px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.unanswered {
   border-bottom: 2px solid #eee;
}
.unanswered a,
.answered a {
   background-color: #08554e;
   color: #fff;
   width: 8vw;
   text-align: center;
   padding: 4px 6px;
   border-radius: 5px;
}
.mobile-accordian .accordion-button:not(.collapsed) {
   color: #3977d3 !important;
   background-color: #dee2e6 !important;
   font-size: 16px;
}
.mobile-accordian .accordion-item h2 {
   color: #000;
   font-size: 18px;
   font-family: "Roboto", sans-serif;
   font-weight: 900 !important;
}
.mobile-accordian.container .main_div {
   background: #285e94 !important;
}
.mobile-accordian .image {
   padding: 10px 0;
}
.mobile-accordian .image h1 {
   color: #000;
   font-size: 18px;
   font-family: "Roboto", sans-serif;
   margin-bottom: 5px;
}
.mobile-accordian .image p {
   color: #2b2727;
   font-size: 15px;
   font-family: "Roboto", sans-serif;
}

.mobile-accordian .accordion-button {
   background-color: #e9ecef !important;
}

.mobile-accordian row:nth-child(3) {
   padding-right: 10px;
}

.mobile-accordian .accordion-body2 .form_col input {
   width: 100%;
}
.mobile-accordian .accordion-body form .row {
   display: flex;
   justify-content: space-between;
}
.mobile-accordian .accordion-body form .row .view-answer {
   width: 50%;
}
.mobile-accordian .accordion-body form button {
   background-color: #08554e;
   color: #fff;
   text-align: center;
   padding: 4px 35px;
   border-radius: 5px;
   margin-top: 15px;
}
 .mobile-accordian input {
   height: 40px;
   padding: 0 15px;
   margin-bottom: 15px;
   color: #857b7b;
   font-weight: 500;
   border: none;
   outline: none;
   background-color: #fff;
   border-radius: 5px;
   box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.2);
}
.mobile-accordian {
   display: none;
}
.fur_oppertunity {
   padding-left: 0 !important;
}
#flush-collapseFour select {
   width: 100%;
   height: 40px;
   padding: 0 15px;
   margin-bottom: 15px;
   color: #857b7b;
   font-weight: 500;
   border: none;
   outline: none;
   background-color: #fff;
   border-radius: 5px;
   box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.2);
   line-height: 2;
}
.select-container.disabled select {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   pointer-events: none;
   padding-right: 10px;
}
.selected-file-name{
   border: 2px solid #08554e;
   border-radius: 4px;
   padding: 4px;
   width: 200px;
   white-space: nowrap;
   overflow: hidden;
}
#exampleModal .modal-body input {
   height: 35px;
}
#exampleModal .modal-footer {
   padding: 0;
}
.search-functionality {
   width: 200px;
   line-height: 1;
   height: 35px;
   padding-left: 15px;
}
.show-business-plan{
   display: none;
}
.vertical{
   display: flex;
   justify-content: flex-start;
   align-items: center;
}
.vertical label{
   display: flex;
   width: auto;
   font-size: 16px;
}
.vertical label input{
   height: 20px;
   width: 20px;
   line-height: 1;
   margin: 0 25px 0 15px;
}
.vertical-table input{
   height: 30px;
   width: 60%;
}
.show-record{
   display: none;
}
.accordion-button{
   padding: .5rem 1rem;
}
.progress-container {
   position: relative;
   width: 130px;
   height: 130px;
   text-align: center;
 }
 
 .progress-circle {
   transition: stroke-dashoffset 0.5s;
   stroke-dasharray: 260; /* Adjust the stroke-dasharray value */
   stroke-linecap: round; /* To make the ends of the circle rounded */
   transform: rotate(-90deg); /* Rotate the circle to start from the top */
 }
 
 .percentage {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   font-size: 16px;
   font-weight: bold;
   color: #333; /* Adjust text color */
 }
 .Table {
   height: auto; 
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
   font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
   font-size: larger;

 }
 
 .Table td, th {
   width: 120px;
   height: 50px;
 } 
 .table-bordered.border-light tr:nth-child(even){
   background-color: rgb(42, 167, 156);
 }
 .table-bordered.border-light tr{
   text-align: center;
   border: 1px solid black;
 }
 .table-bordered.border-light tr{
   border-left: 2px solid black;
   border-right: 2px solid black;
 }
 .table-bordered.border-light .top-border{
   border-top: 2px solid black;
 }
 .table-bordered.border-light tr:last-child{
   border-bottom: 2px solid black;
 }
 .table-bordered.border-light th,
 .table-bordered.border-light td{
   border: 1px solid black;
 }
 .extra{
   border-bottom: .001px solid #074d47 !important;
 }
 table{
   text-transform: capitalize;
 }
 .score{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }
 .score .engagementScore{
   border-radius: 100%;
   padding: 30px 20px;
   border: 10px dashed #074d47;
   margin-bottom: 10px;
 }
 .SupportTeam{
   margin-top:20px;
   height: auto;
  
   }
   .SupportTeam  .container{
       border-bottom: 1px solid black;
      height: auto;
   }
   .SupportTeam .card {
      
       margin-bottom: 24px;
       box-shadow: 0 2px 3px #e4e8f0;
   }
   .SupportTeam  .card {
       display: flex;
       min-width: 0;
       word-wrap: break-word;
       background-color: #fff;
       background-clip: border-box;
       border: 1px solid #eff0f2;
       border-radius: 1rem;
      
   }
   .SupportTeam .card  .card-body{
       display: flex;
       justify-content: space-evenly;
       align-items: center;
       padding: 4px;

   }
  

   @media screen and (max-width:700px){
      .SupportTeam .card-body{
       flex-direction: column;
     }
     .SupportTeam .cardContent{
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items:center;

   }
   .SupportTeam .card-body .flex-1{
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
   }
   .SupportTeam .d-flex{
       justify-content: center;
   }
   .SupportTeam h5{
       text-align: center;
   }
   .SupportTeam  .level{
       margin-bottom: 15px;
   }
   .SupportTeam  .mb-3{
       display: flex;
       justify-content: center;
   }

   }
   .SupportTeam h4,h2{
   font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
   color: rgb(83, 83, 83);
 }
 
 .SupportTeam .avatar-md {
       height: 8rem;
       width: 8rem;
   }
   .SupportTeam  .rounded-circle {
       border-radius: 50%!important;
   }
   .SupportTeam  .img-thumbnail {
       padding: 0.25rem;
       background-color: #f1f3f7;
       border: 1px solid #eff0f2;
       border-radius: 0.75rem;
   }
   .SupportTeam  .avatar-title {
       align-items: center;
       background-color: #3b76e1;
       color: #fff;
       display: flex;
       font-weight: 500;
       height: 100%;
       justify-content: center;
       width: 100%;
   }
   .bg-soft-primary {
       background-color: rgba(59,118,225,.25)!important;
   }
   a {
       text-decoration: none!important;
   }
   .badge-soft-danger {
       color: #f56e6e !important;
       background-color: rgba(245,110,110,.1);
   }
   .badge-soft-success {
       color: #63ad6f !important;
       background-color: rgba(99,173,111,.1);
   }
   .mb-0 {
       margin-bottom: 0!important;
   }
   .badge {
       display: inline-block;
       padding: 0.25em 0.6em;
       font-size: 75%;
       font-weight: 500;
       line-height: 1;
       color: #fff;
       text-align: center;
       white-space: nowrap;
       vertical-align: baseline;
       border-radius: 0.75rem;
   }
  
@media (min-width: 1200px) {
   .prospect_info {
      width: 40.66666667%;
   }
}

@media (max-width: 1200px) {
   .lead-status,
   .cross-selling,
   .query-area,
   .quality-score {
      margin-top: 60px;
   }
}
@media (max-width: 768px) {
   .tool-tip {
      width: 60%;
      right: 7%;
   }
}
@media screen and (max-width: 600px) {
   #Iframe-Master-CC-and-Rs {
      padding: 0 15px;
      max-width: 100%;
   }
   .responsive-wrapper-wxh-572x612 {
      height: 75vh;
   }
   .agreement__doc__wrap {
      width: 92%;
      left: 16px;
   }
   .pdf-page .page .d-flex .img-box img {
      width: 110px;
      height: 50px;
      margin: auto;
   }
   .reg-btn.me-2 {
      display: none;
   }
   #sidebar_nav_toggle {
      position: absolute;
      right: 25px;
   }
   #sidebar_nav_toggle > i {
      font-size: 30px;
   }
   .hidden-bookAppointment {
      display: block;
   }
}
@media (max-width: 480px) {
   .tool-tip {
      width: 100%;
      height: 40vh;
   }
   .content-tag{
      overflow: auto;
   }
   .detail_heading, .detail_value{
      font-size: 14px;
   }
   .leadstatus-option{
      font-size: 12px;
   }
   .score .engagementScore{
      padding: 15px 10px;
      font-size: 12px;
   }
}
