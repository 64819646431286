/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap');

:root {
    /* --font-default: 'Red Hat Display', sans-serif;
  --font-primary: 'Red Hat Display', sans-serif;
  --font-secondary: 'Red Hat Display', sans-serif; */
    --font-default: 'Source Sans Pro', sans-serif;
    --font-primary: 'Source Sans Pro', sans-serif;
    --font-secondary: 'Source Sans Pro', sans-serif;
}

/* Colors */
:root {
    --color-default: #0a0d13;
    --color-primary: #00ad51;
    --color-secondary: #0e1d34;
    --color-logoBlue: #2492eb;
    --color-lightGray: #ebe8e8;
    --color-grayDark: #857b7b;
}


/* body {
    background: #f2f4f8;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    height: 100vh;
    font-family: "Open Sans";
  } */

.education {
    --bg-color: #ffd861;
    --bg-color-light: #ffeeba;
    --text-color-hover: #4C5656;
    /* --text-color-hover: #fff; */
    --box-shadow-color: rgba(255, 215, 97, 0.48);
}

.credentialing {
    --bg-color: #B8F9D3;
    --bg-color-light: #e2fced;
    --text-color-hover: #4C5656;
    /* --text-color-hover: #fff; */
    --box-shadow-color: rgba(184, 249, 211, 0.48);
}

.wallet {
    --bg-color: #CEB2FC;
    --bg-color-light: #F0E7FF;
    --text-color-hover: #fff;
    --box-shadow-color: rgba(206, 178, 252, 0.48);
}

.human-resources {
    --bg-color: #DCE9FF;
    --bg-color-light: #f1f7ff;
    --text-color-hover: #4C5656;
    /* --text-color-hover: #fff; */
    --box-shadow-color: rgba(220, 233, 255, 0.48);
}

.hover_card {
    text-align: center;
    margin: auto;
    max-width: 270px;
    height: 220px;
    background: #fff;
    /* border-top-right-radius: 10px; */
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
    transition: all 0.3s ease-out;
    text-decoration: none;
}

@media screen and (max-width: 576px) {
    .hover_card {
        max-width: 100%;
    }
}

.hover_card:hover {
    transform: translateY(-5px) scale(1.005) translateZ(0);
    box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
        0 24px 46px var(--box-shadow-color);
}

.hover_card:hover .overlay {
    transform: scale(4) translateZ(0);
}

.hover_card:hover .circle {
    border-color: var(--bg-color-light);
    background: var(--bg-color);
}

.hover_card:hover .circle:after {
    background: var(--bg-color-light);
}

.hover_card:hover p {
    color: var(--text-color-hover);
}

.hover_card:active {
    transform: scale(1) translateZ(0);
    box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
        0 15px 24px var(--box-shadow-color);
}

.hover_card p {
    font-size: 15px;
    font-weight: 600;
    color: #4C5656;
    margin-top: 15px;
    margin-bottom: 0;
    z-index: 1;
    transition: color 0.3s ease-out;
}

.hover_card .circle {
    width: 148px;
    height: 148px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid var(--bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-out;
}

.hover_card .circle:after {
    content: "";
    width: 130px;
    height: 130px;
    display: block;
    position: absolute;
    background: var(--bg-color);
    border-radius: 50%;
    top: 10px;
    left: 10px;
    transition: opacity 0.3s ease-out;
}

.hover_card .circle svg,
.hover_card .circle img {
    z-index: 10000;
    transform: translateZ(0);
    width: 110px;
}

.hover_card .overlay {
    width: 135px;
    position: absolute;
    height: 135px;
    border-radius: 50%;
    background: var(--bg-color);
    top: 3%;
    left: 3%;
    z-index: 0;
    transition: transform 0.3s ease-out;
}

.card_hover_sha {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.inv_card_cont_wrapper,
.inv_card_cont_wrapper_2 {
    background-color: #edfffb;
    border: 1px solid var(--color-lightGray);
    padding: 30px 10px 15px 10px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 8px;
}

.inv_card_cont_wrapper_2 {
    background-color: #f2f4f8;
}

/* #################Image card Styling################# */
.imageCardWrapper .cards {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    max-width: 820px;
}

.imageCardWrapper .card {
    /* margin-right: 5px; */
    transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
    background-color: #fff;
    /* max-width: 33.3%; */
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    /* box-shadow: 0px 13px 10px 7px rgba(0, 0, 0, 0.1); */
    box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
    /* transform: scale(1.10, 1.10); */
}

.imageCardWrapper .card--1 .card__img--hover {
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: cover; */
}

.imageCardWrapper .card__clock-info {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    /* opacity: 0;
    visibility: hidden; */
}
.imageCardWrapper .card__clock-info img {
    width: 40px;
    display: block;
}
.imageCardWrapper .card__img {
    visibility: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

}

.imageCardWrapper .card__info-hover {
    position: absolute;
    padding: 16px;
    width: 100%;
    opacity: 1;
    z-index: 0;
    top: 30%;
    /* transition: 0.2s; */
    opacity: 1;
    visibility: visible;
    z-index: 2;
}

.imageCardWrapper .card__img--hover {
    transition: 0.2s all ease-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    position: absolute;
    height: 200px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    top: 0;

}

.imageCardWrapper .card:hover {
    box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
    transform: scale(1.10, 1.10);
}

.imageCardWrapper .card:hover .card__info-hover {
    opacity: 1;
    visibility: visible;
    z-index: 2;
}
.imageCardWrapper .card__info {
    z-index: 2;
    background-color: #fff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 10px;
}

.imageCardWrapper .card__category {
    font-family: var(--font-default);
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 500;
    color: #868686;
}

.imageCardWrapper .card__title {
    font-size: 18px;
    /* margin-top: 5px; */
    /* margin-bottom: 10px; */
    font-family: var(--font-default);
    height: 45px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.imageCardWrapper .card__by {
    font-size: 12px;
    font-family: var(--font-default);
    font-weight: 500;
}

.imageCardWrapper .card__author {
    font-weight: 600;
    text-decoration: none;
    color: #AD7D52;
}

.imageCardWrapper .card:hover .card__img--hover {
    height: 250px;
    /* opacity: 0.3; */
}

.imageCardWrapper .card:hover .imageCardWrapper .card__info {
    background-color: transparent;
    position: relative;
}

.imageCardWrapper .card:hover .imageCardWrapper .card__info-hover {
    opacity: 1;
}
.imageCardWrapper .bgImgOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    /* background-color: rgba(0, 0, 0, 0); */
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 0.3s ;
    z-index: 1;
}
.imageCardWrapper .card:hover .bgImgOverlay {
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 0.3s ;
}

/* =================Creatives Page Design========= */
.creative__image_wrap .box {
    position: relative;
    width: 15rem;
    height: 15rem;
    margin: 2rem;
  }
  
  .creative__image_wrap .box:hover .imgBox {
    transform: translate(-0.2rem, -2.2rem);
    /* transform: translate(-0.3rem, -2.5rem); */
  }
  
  .creative__image_wrap .box:hover .content {
    transform: translate(1.5rem, 1rem);
    /* transform: translate(2rem, 2.5rem); */
  }
  .creative__image_wrap .box .imgBox {
    box-shadow: 5px 5px 5px rgba(0,0,0,0.1);
    transform: translate(0rem, -2.2rem);
  }
  
  .creative__image_wrap .box .content {
    transform: translate(2rem, 1.1rem);
  }
  
  .creative__image_wrap .imgBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all 0.5s ease-in-out;
  }
  
  .creative__image_wrap .imgBox img {
    max-width: 100%;
    height: 15rem;
    object-fit: cover;
    resize: both;
    border-radius: 10px;
  }
  
  .creative__image_wrap .content {
    position: absolute;
    top: 0;
    left: 0;
    width: -webkit-fill-available;
    height: 100%;
    padding: 0px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    z-index: 1;
    align-items: flex-end;
    text-align: center;
    transition: 0.5s ease-in-out;
    border-radius: 10px;
    box-shadow: 8px 8px 5px rgba(0, 0, 0, 0.1);
    padding-bottom: 3px;
  }
  
  .creative__image_wrap .content h2 {
    /* display: block;
    font-size: 20px;
    color: #111;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 1px; */
  }
  
  .creative__image_wrap .content .subBtn {
    border-radius: 20px;
	text-align: center;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 8px 25px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: 0.3 ease-in;
    position: relative;
  }

  .creative__image_wrap .content .subBtn:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  }
  
  @media (max-width: 600px) {
    .creative__image_wrap .box {
        margin: 0;
        margin-bottom: 2rem;
    }
    /* .creative__image_wrap .box:hover .content {
      transform: translate(0, 2.4rem);
    }
    .creative__image_wrap .box:hover .imgBox {
      transform: translate(0, -2rem);
    } */
    .creative__image_wrap .box .content {
        transform: translate(0, 2.4rem);
      }
      .creative__image_wrap .box .imgBox {
        transform: translate(0, -2rem);
      }
  }
  /*# sourceMappingURL=main.css.map */