/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap');

:root {
    /* --font-default: 'Red Hat Display', sans-serif;
  --font-primary: 'Red Hat Display', sans-serif;
  --font-secondary: 'Red Hat Display', sans-serif; */
    --font-default: 'Source Sans Pro', sans-serif;
    --font-primary: 'Source Sans Pro', sans-serif;
    --font-secondary: 'Source Sans Pro', sans-serif;
}

/* Colors */
:root {
    --color-default: #0a0d13;
    --color-primary: #00ad51;
    --color-secondary: #0e1d34;
    --color-logoBlue: #2492eb;
    --color-lightGray: #ebe8e8;
    --color-grayDark: #857b7b;
}

/* quiz css */
.quiz__wrapper {
    padding: 10px 5px 0 5px;
}

.quiz__questions p {
    margin-bottom: 5px;
    /* color: var(--color-grayDark); */
    color: #3f4b5b;
    font-size: 18px;
    font-weight: 500;
}

.quiz__options label {
    width: 95%;
    /* width: fit-content; */
    font-size: 14px;
    color: #7a7676;
    margin-left: 5px;
    margin-top: 4px;
}

@media screen and (max-width: 768px) {
    .quiz__options label {
        width: 90%;
        /* width: fit-content; */
        font-size: 14px;
        color: #7a7676;
        margin-left: 5px;
        margin-top: 4px;
    }
}

.quiz__options input {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    margin-right: 5px !important;
}

@media screen and (max-width: 600px) {
    .quiz__wrapper {
        padding: 10px 0;
    }
}

.btn__cont {
    display: flex;
    align-items: center;
    justify-content: end;
}

/* .assessment_form label {
    display: block;
    margin-bottom: 0.5rem;
} */


/* .assessment_form .width-50 {
    width: 50%;
} */

.assessment_form .ml-auto {
    margin-left: auto;
}

.assessment_form .text-center {
    text-align: center;
}

/* Progressbar */
.assessment_form .progressbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    counter-reset: step;
    margin: 2rem 0 2rem;
}

.registration__popup .progressbar {
    counter-reset: unset;
    margin: 5px;
    justify-content: center;
}

.assessment_form .progressbar::before,
.assessment_form .progress {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 4px;
    width: 100%;
    background-color: #dcdcdc;
    z-index: -1;
}

.assessment_form .progress {
    background-color: var(--color-primary);
    width: 0%;
    transition: 0.3s;
}

.assessment_form .progress-step {
    width: 2.1875rem;
    height: 2.1875rem;
    background-color: #dcdcdc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.progressDotsCont .progress-step {
    width: 9px;
    height: 9px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #dcdcdc;
}

.progressDotsCont {
    position: absolute;
    bottom: 13%;
    left: 50%;
    transform: translateX(-50%);
}

.progressDotsCont .progress-step.active {
    background-color: var(--color-primary);
}

.assessment_form .progress-step::before {
    counter-increment: step;
    content: counter(step);
}

.registration__popup .progress-step::before {
    counter-increment: unset;
    content: '';
}

.assessment_form .progress-step::after {
    /* content: attr(data-title); */
    position: absolute;
    top: calc(100% + 0.5rem);
    font-size: 0.85rem;
    color: #666;
}

.assessment_form .progress-step-active {
    background-color: var(--color-primary);
    color: #f3f3f3;
}

/* Form */
.assessment_form .form {
    width: 100%;
    margin: 0 auto;
    /* border: 1px solid #ccc; */
    /* border-radius: 0.35rem; */
    /* padding: 1.5rem; */
}

.assessment_form .form-step {
    display: none;
    transform-origin: top;
    animation: animate 0.5s;
}

.assessment_form .form-step-active {
    display: block;
}

.assessment_form .input-group {
    display: flex;
    align-items: start;
    justify-content: start;
    margin-bottom: 5px;
}

@keyframes animate {
    from {
        transform: scale(1, 0);
        opacity: 0;
    }

    to {
        transform: scale(1, 1);
        opacity: 1;
    }
}

/* Button */
/* .assessment_form .btns-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
} */

.assessment_form .btn {
    /* padding: 10px; */
    display: block;
    text-decoration: none;
    background-color: var(--primary-color);
    color: #f3f3f3;
    text-align: center;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: 0.3s;
}

.assessment_form .btn-prev {
    margin-right: 10px;
}

.assessment_form .btn-next,
.assessment_form .btn-prev,
.assessment_form .btn-submit,
.assessment_form .btn_download {
    background-color: #0ea89a;
    border: none;
}

.assessment_form .score_board {
    color: #0ea89a;
}

.assessment_form input.btn-submit {
    width: fit-content !important;
    height: auto !important;
}

.assessment_form .btn:hover {
    box-shadow: 0 0 0 2px #fff, 0 0 0 3px var(--primary-color);
}

/* ========= UPdate PRofile Ques*/
.updateProfileForm {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.profile_form__container {
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    padding: 10px;
    transition: all 0.3s;
}

.updpro_form {
    transition: all 0.3s;
}

.updateProfileForm .form-check-label {
    font-size: 14px;
    color: #6b6969;
}

.updateProfileForm .profile___ques {
    font-size: 15px;
}

.pro__ques_subm {
    background-color: #ed6755;
    border: none;
    border-radius: 5px;
    /* width: 200px; */
    padding: 8px 30px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
}

.pro__ques_subm.arrows {
    width: 35px;
    height: 35px;
    padding: 0;
    border-radius: 50%;
    display: none;
}

.slide__navigation {
    position: absolute;
    right: 20px;
    bottom: 15px;
}

.slide__navigation.lastPrev {
    right: 135px;
    bottom: 13px;
    transition: all 0.3s;
}

.lastPrev.lastPrevBtnTran {
    bottom: -210px;
}

@media screen and (min-width: 600px) {
    .pro__ques_subm.arrows {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .profile_form__container {
        /* max-height: 618px; */
        overflow: hidden;
    }
}