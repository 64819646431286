/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap");

:root {
   /* --font-default: 'Red Hat Display', sans-serif;
  --font-primary: 'Red Hat Display', sans-serif;
  --font-secondary: 'Red Hat Display', sans-serif; */
   --font-default: "Source Sans Pro", sans-serif;
   --font-primary: "Source Sans Pro", sans-serif;
   --font-secondary: "Source Sans Pro", sans-serif;
}

/* Colors */
:root {
   --color-default: #0a0d13;
   --color-primary: #00ad51;
   --color-secondary: #0e1d34;
   --color-logoBlue: #2492eb;
   --color-lightGray: #ebe8e8;
   --color-grayDark: #857b7b;
}

:root {
   --font-default: "Red Hat Display", sans-serif;
   --font-primary: "Red Hat Display", sans-serif;
   --font-secondary: "Red Hat Display", sans-serif;
}

/* Animations */
@keyframes jumpInfinite {
   0% {
      transform: translateY(0px);
   }

   50% {
      transform: translateY(8px);
   }

   100% {
      transform: translateY(0px);
   }
}

/* ================ Lead ============== */

.detail_heading,
.detail_value {
   font-size: 16px;
   font-weight: 450;
}

.detail_heading {
   color: var(--color-primary);
   /* font-weight: 550; */
}

.jumpAnima {
   animation: jumpInfinite 1.5s infinite;
}

/* ==========lead management dummy input detials data */
.fields__container {
   max-height: 440px;
   /* overflow: scroll; */
   border: 1px solid var(--color-lightGray);
   padding: 10px 5px 10px 0px;
   border-radius: 8px;
   box-shadow: 0 0 5px rgba(0, 0, 0, 0.0777) inset;
   font-family: var(--font-default);
   /* margin: 10px 0; */
}

.input__label__group__col {
   margin-bottom: 10px;
}

.input__label__group .lead_d_label {
   font-size: 14px;
   color: var(--color-primary);
   z-index: 0;
}

.input__label__group .lead__d_input,
.input__label__group .lead__d_select {
   z-index: 0;
   width: 100%;
   background-color: #eee;
   padding-left: 8px;
   height: 40px;
   border-radius: 8px;
   border: none;
   border-bottom: 1px solid var(--color-logoBlue);
   outline: none;
   font-size: 14px;
}

.input__label__group .lead__d_input:focus,
.input__label__group .lead__d_select:focus {
   background-color: #fff;
   border: 1px solid var(--color-logoBlue);
   box-shadow: 0 0 0 1px var(--color-logoBlue);
}

.fields__container .input__groups {
   /* transform: translateY(-203%); */
   transition: all 0.3s;
}

.arrow__right {
   text-align: -webkit-right;
}

.arrow__left {
   text-align: -webkit-left;
}

.arrow__btn {
   background-color: var(--color-logoBlue);
   color: #fff;
   border-radius: 50%;
   padding: 10px;
   width: 35px;
   line-height: 1;
   height: 35px;
   cursor: pointer;
}

.actns__btn {
   padding: 3px 10px;
   background-color: #ed6755;
   color: white;
   box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
}
.actns__btn:hover {
   background: #ff7765;
   color: #fff;
}
/* auto suggest city input styling */
.lead__autoSugg_input .select__input,
.lead__autoSugg_input .select__control {
   min-height: 40px !important;
   font-size: 14px;
}

.lead__autoSugg_input .css-13cymwt-control {
   width: 100%;
   background-color: #eee;
   padding: 0;
   min-height: 40px;
   border-radius: 8px;
   border: none;
   border-bottom: 1px solid var(--color-logoBlue) !important;
   outline: none;
   font-size: 14px;
}

/* .lead__autoSugg_input .css-t3ipsp-control:focus {
    border-radius: 8px;
    border: none;
    outline: none !important;
    border-width: 0px !important;
    box-shadow: none !important;
    background-color: #eee;
    border-bottom: 1px solid var(--color-logoBlue) !important;
} */

.lead__autoSugg_input .css-qbdosj-Input,
.lead__autoSugg_input .css-1fdsijx-ValueContainer {
   padding: 0 !important;
   min-height: 40px !important;
}
.lead__autoSugg_input .css-qbdosj-Input,
.lead__autoSugg_input .css-1jqq78o-placeholder {
   margin: 0 0 0 8px !important;
   /* color: black; */
}
.select__menu {
   /* z-index: 999999999999 !important; */
}

/* ======For Large screen size===== */
.arrow__transform__btn {
   display: none;
}
@media screen and (min-width: 768px) {
   .arrow__transform__btn {
      display: block;
   }
   .fields__container {
      max-height: 455px;
   }
   .input__groups {
      /* margin: 15px 0; */
   }
}
.fields__container .input__groups {
   /* transform: translateY(-150%) !important;  */
   transition: all 0.3s;
}

/* =============Tabs view Inputs */

/* <--------------  LMS  ------------> */
.field__container {
   border: 1px solid var(--color-lightGray);
   border-radius: 8px;
   box-shadow: 0 0 5px rgba(0, 0, 0, 0.0777) inset;
   font-family: var(--font-default);
}

.custom-input-field {
   height: 35px;
   /* width: auto; */
   border: 2px solid #08554e;
   border-radius: 5px;
   padding: 0 10px;
   color: black;
}
.small_icon {
   color: #00ad51;
   cursor: pointer;
}

.heading-text-large {
   font-size: 1.3rem !important;
}
@media (max-width: 480px) {
   .detail_heading, .detail_value{
      font-size: 12px;
   }
}