/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap");

:root {
   /* --font-default: 'Red Hat Display', sans-serif;
  --font-primary: 'Red Hat Display', sans-serif;
  --font-secondary: 'Red Hat Display', sans-serif; */
   --font-default: "Source Sans Pro", sans-serif;
   --font-primary: "Source Sans Pro", sans-serif;
   --font-secondary: "Source Sans Pro", sans-serif;
}

/* Colors */
:root {
   --color-default: #0a0d13;
   --color-primary: #00ad51;
   --color-secondary: #0e1d34;
   --color-logoBlue: #2492eb;
   --color-lightGray: #ebe8e8;
   --color-grayDark: #857b7b;
}

@keyframes down-btn {
   0% {
      bottom: 20px;
   }

   100% {
      bottom: 0px;
   }

   0% {
      opacity: 0;
   }

   100% {
      opaicty: 1;
   }
}

@-webkit-keyframes down-btn {
   0% {
      bottom: 20px;
   }

   100% {
      bottom: 0px;
   }

   0% {
      opacity: 0;
   }

   100% {
      opaicty: 1;
   }
}

@-moz-keyframes down-btn {
   0% {
      bottom: 20px;
   }

   100% {
      bottom: 0px;
   }

   0% {
      opacity: 0;
   }

   100% {
      opaicty: 1;
   }
}

@-o-keyframes down-btn {
   0% {
      bottom: 20px;
   }

   100% {
      bottom: 0px;
   }

   0% {
      opacity: 0;
   }

   100% {
      opaicty: 1;
   }
}

body {
   margin: 0;
}

.category-name {
   font-family: var(--font-primary);
   width: -webkit-fill-available;
   text-align: center;
   font-size: 40px;
}

.card-category-2 ul,
.card-category-3 ul,
.card-category-4 ul,
.card-category-5 ul .card-category-6 ul {
   padding: 0;
}

.card-category-2 ul li,
.card-category-3 ul li,
.card-category-4 ul li,
.card-category-5 ul li,
.card-category-6 ul li {
   list-style-type: none;
   display: inline-block;
   vertical-align: top;
}

.card-category-2 ul li,
.card-category-3 ul li {
   margin: 10px 5px;
}

.card-category-1 {
   font-family: var(--font-primary);
   margin-bottom: 45px;
   text-align: center;
}

.card-category-1 div,
.card-category-2 div {
   display: inline-block;
}

.card-category-1 > div,
.card-category-2 > div:not(:last-child) {
   margin: 10px 5px;
   text-align: left;
}

/* Basic Card */
.basic-card {
   /* width: 300px; */
   position: relative;

   -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
   -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
   -o-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
   box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
}

.basic-card .card-content {
   padding: 15px;
}

.dash-card-content {
   min-height: 200px;
}

.dash-card-content .card-title {
   margin-bottom: 20px;
   padding-bottom: 10px;
   border-bottom: 1px solid #d9d9d9;
}

.dash-card-content .card-text {
   display: -webkit-box;
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
}

.basic-card .card-title {
   font-size: 18px;
   display: block;
   /* margin-bottom: 15px; */
   text-transform: capitalize;
   font-family: var(--font-primary);
}

.basic-card .card-text {
   line-height: 1.6;
}

.basic-card .card-link {
   padding: 0 15px;
   width: -webkit-fill-available;
}

.basic-card .card-link a {
   text-decoration: none;
   position: relative;
   padding: 10px 0px;
}

.basic-card .card-link a:after {
   top: 30px;
   content: "";
   display: block;
   height: 0px;
   left: 50%;
   position: absolute;
   width: 0;
   -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
   -moz-transition: width 0.3s ease 0s, left 0.3s ease 0s;
   -o-transition: width 0.3s ease 0s, left 0.3s ease 0s;
   transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

.basic-card .card-link a:hover:after {
   width: 100%;
   left: 0;
}

.slick-slide:nth-child(odd) .card-bg {
   background-image: linear-gradient(
      to bottom right,
      #00bfad,
      #99a3d4
   ) !important;
}

.slick-slide:nth-child(even) .card-bg {
   background-image: linear-gradient(
      to bottom right,
      #34cf65,
      #828bff
   ) !important;
}

.slick-slide .card-bg .card-link {
   border-top: 1px solid #d9d9d9;
}

/* slick buttons */
.slick-prev:before,
.slick-next:before {
   color: var(--color-default);
   font-size: 28px;
   opacity: 1;
}

.slick-next {
   right: -10px;
}

/* .details-page-card:nth-child(odd) .card-bg {
    background-image: linear-gradient(to bottom right, #00bfad, #99a3d4) !important;
} */

.details-page-card:nth-child(odd) .card-bg {
   /* background-image: linear-gradient(260deg, #34cf65, #828bff) !important; */
   background: #9982f4;
}

.list-card-container:nth-child(even) .listing-card {
   background: #9982f4;
   /* background-image: linear-gradient(260deg, #34cf65, #828bff) !important; */
}

.video-listing-sec:nth-child(odd) .card-bg {
   background: #9982f4;
}

.details-card .card-content {
   padding-top: 0px;
   padding-bottom: 0px;
}

.card-bg-1 {
   background: #52c2c9;
}

.card-bg-5 {
   background-image: linear-gradient(to bottom right, #ce74c2, #a987ff);
}

.card-bg-6 {
   background: linear-gradient(150deg, #5ecc81, #8a6bda);
}

.card-bg-1 .card-content,
.basic-card .card-link a {
   color: #fff;
}

.card-bg-1 .card-link {
   border-top: 1px solid #82c1bb;
}

.card-bg-1 .card-link a:after {
   background: #fff;
}

.card-bg-2 {
   background-image: linear-gradient(to bottom right, #ec407b, #ff7d94);
}

.card-bg-2 .card-content {
   color: #fff;
}

.card-bg-2 .card-link {
   border-top: 1px solid #ff97ba;
}

.card-bg-2 .card-link a:after {
   background: #fff;
}

.card-bg-3 {
   border: 1px solid #eee;
}

.card-bg-3 .card-title,
.card-bg-3 .card-link a {
   color: #636363;
}

.card-bg-3 .card-text {
   color: #7b7b7b;
}

.card-bg-3 .card-link {
   border-top: 1px solid #eee;
}

.card-bg-3 .card-link a:after {
   background: #636363;
}

.card-bg-4 {
   background-image: linear-gradient(to bottom right, #252525, #4a4a4a);
}

.card-bg-4 .card-title,
.card-bg-4 .card-link a {
   color: #eee;
}

.card-bg-4 .card-text {
   color: #dcdcdcdd;
}

.card-bg-4 .card-link {
   border-top: 1px solid #636363;
}

.card-bg-4 .card-link a:after {
   background: #eee;
}

.wrapper {
   display: inline-block;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.video-main {
   position: relative;
   display: inline-block;
}

.video-box {
   cursor: pointer;
}

.video {
   height: 50px;
   width: 50px;
   line-height: 50px;
   text-align: center;
   border-radius: 100%;
   background: transparent;
   color: #fff;
   display: inline-block;
   background: #000000;
   z-index: 999;
}

.play-btn-container {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.video-box .img-container img {
   width: 150px;
   border-radius: 50%;
}

.play-btn {
   width: 50px;
   height: 50px;
   background: radial-gradient(
      var(--color-logoBlue) 60%,
      rgba(255, 255, 255, 1) 62%
   );
   border-radius: 50%;
   position: relative;
   display: block;
   margin: 100px auto;
   box-shadow: 0px 0px 25px 3px var(--color-primary);
}

/* triangle */
.play-btn::after {
   content: "";
   position: absolute;
   left: 50%;
   top: 50%;
   -webkit-transform: translateX(-40%) translateY(-50%);
   transform: translateX(-40%) translateY(-50%);
   transform-origin: center center;
   width: 0;
   height: 0;
   border-top: 15px solid transparent;
   border-bottom: 15px solid transparent;
   border-left: 25px solid #fff;
   z-index: 100;
   -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
   transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

/* pulse wave */
.play-btn:before {
   content: "";
   position: absolute;
   width: 160%;
   height: 160%;
   -webkit-animation-delay: 0s;
   animation-delay: 0s;
   -webkit-animation: pulsate1 2s;
   animation: pulsate1 2s;
   -webkit-animation-direction: forwards;
   animation-direction: forwards;
   -webkit-animation-iteration-count: infinite;
   animation-iteration-count: infinite;
   -webkit-animation-timing-function: steps;
   animation-timing-function: steps;
   opacity: 1;
   border-radius: 50%;
   border: 5px solid rgba(255, 255, 255, 0.75);
   top: -30%;
   left: -30%;
   background: rgba(198, 16, 0, 0);
}

@-webkit-keyframes pulsate1 {
   0% {
      -webkit-transform: scale(0.6);
      transform: scale(0.6);
      opacity: 1;
      box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
         0px 0px 25px 10px rgba(255, 255, 255, 0.75);
   }

   100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
      box-shadow: none;
   }
}

@keyframes pulsate1 {
   0% {
      -webkit-transform: scale(0.6);
      transform: scale(0.6);
      opacity: 1;
      box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
         0px 0px 25px 10px rgba(255, 255, 255, 0.75);
   }

   100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1);
      opacity: 0;
      box-shadow: none;
   }
}

/* Details card styling */
.details-card {
   display: flex !important;
   justify-content: space-between;
   /* align-items: center; */
   /* padding: 20px 0; */
   border-radius: 15px;
}

.sideXpandWrapp .details-card {
   padding-bottom: 30px;
}

.details-card .card-link {
   border: none;
   text-align: center;
   width: auto;
   /* display: flex;
    flex-direction: column;
    align-items: flex-end; */
}

.details-card .card-link a {
   display: block;
   width: 150px;
   border: 1px solid;
   padding: 8px;
   font-size: 14px;
   margin: 8px 0;
   border-radius: 20px;
   overflow: hidden;
}

@media screen and (max-width: 520px) {
   .details-card .card-link a {
      width: 100px;
   }
}

.details-card .card-link a:hover::after {
   text-decoration: none;
   width: 0%;
   height: 0%;
}

.details-card .card-link a:hover {
   box-shadow: 0 0 20px rgba(0, 0, 0, 0.315);
   transform: rotateX(18deg);
}

.details-card-wrapper {
   padding: 15px;
   margin: 20px 0;
}

.details-card .card-title {
   margin-bottom: 10px;
}

@media screen and (min-width: 991px) {
   /* .details-page-card {
        width: 90%;
    } */
}

@media screen and (max-width: 992px) {
   .list-card-wrapper .card-link {
      padding: 0 5px;
   }
}

/* Listing page card css */
.listing-card {
   border-radius: 15px;
   margin-bottom: 20px;
}

.listing-card .card-link {
   text-align: center;
   border-top: none;
   /* display: flex; */
   width: auto;
   /* flex-direction: row; */
   /* align-items: flex-start;
    justify-content: flex-end; */
}

.listing-content {
   padding-top: 0px !important;
   padding-bottom: 0px !important;
   margin-bottom: 25px;
   align-items: center;
   display: flex;
   align-items: center;
   justify-content: center;
}

.listing-content .title {
   text-align: center;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.list-card-wrapper {
   /* display: flex;
    justify-content: space-between; */
   /* border-bottom: 1px solid #c5c5c5; */
   padding: 15px 0;
}

.listing-card .card-title,
.video-card-text {
   text-transform: capitalize;
   /* margin-right: 8px; */
   margin-bottom: 15px;
   display: -webkit-box !important;
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-line-clamp: 2;
   min-height: 55px;
   -webkit-box-orient: vertical;
}

.listing-card .card-link a {
   display: block;
   width: 120px;
   border: 1px solid;
   padding: 8px;
   font-size: 14px;
   overflow: hidden;
   border-radius: 25px;
}

.listing-card .card-link a:first-child {
   margin-right: 8px;
}

.listing-img {
   /* margin-right: 15px; */
   /* border: 1px solid; */
   padding: 10px;
   box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
   border-radius: 50%;
   background-color: #fff;
}

.listing-img img {
   width: 90px;
   /* height: 100%; */
   border-radius: 5px;
}

.listing-page-sec {
   padding: 20px 25px;
}

.listing-card .card-link a:hover {
   box-shadow: 0 0 20px rgba(0, 0, 0, 0.315);
   /* transform: rotateX(18deg); */
}

.listing-card .card-link {
   display: flex;
   margin-top: 25px;
   justify-content: center;
}

/* .listing-page-sec .inner-content {
    margin-bottom: 30px;
} */

@media screen and (max-width: 768px) {
   /* .listing-card .card-link {
        display: flex;
        justify-content: center !important;
        margin-top: 15px;
    } */

   .listing-card .listing-content {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
   }

   .listing-card .listing-content .card-text {
      text-align: justify;
   }

   .listing-card .card-title {
      margin-bottom: 5px;
   }

   /* .video-listing .listing-card .listing-content {
        text-align: center;
    } */

   .listing-img {
      margin-right: 0;
   }

   /* .listing-img img {
        width: 150px;
        height: 100%;
        border-radius: 5px
    } */

   .listing-card .listing-img {
      margin-top: 15px;
   }

   /* 
    .list-card-wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    } */
}

/* franchise landing page card */
.langing_cards_main {
   margin-bottom: 15px;
   width: 25%;
}

.investor_dashboard .langing_cards_main {
   /* width: 33.33%; */
   text-align: center;
}

.investor_dashboard .landing_amount_cont {
   justify-content: center;
}

.langing_cards_main .wrap {
   /* text-align: center; */
   background: #ffffff;
   /* box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1); */
   /* border-radius: 7px; */
   /* text-align: center; */
   position: relative;
   overflow: hidden;
   /* padding: 40px 25px 20px; */
   padding: 15px 8px 15px 0;
   height: 100%;
   border-right: 1px solid #ebe8e8;
}

.langing_cards_main:last-child .wrap {
   border: none;
}

.langing_cards_main_title,
.c-dashboardInfo__subInfo {
   color: #6c6c6c;
   font-size: 18px;
}

.langing_cards_main span {
   display: block;
}

.landing_cards__count {
   font-weight: 500;
   font-size: 20px;
   /* line-height: 64px; */
   color: #323c43;
   padding-right: 5px;
}

.landing_amount_cont .put_up_value {
   font-size: 10px;
   font-weight: 600;
   background-color: #18f70a33;
   color: #07c507d9;
   padding: 2px 6px;
   border-radius: 20px;
}

.landing_amount_cont .put_down_value {
   font-size: 10px;
   font-weight: 600;
   padding: 2px 6px;
   border-radius: 20px;
   background-color: #ff000026 !important;
   color: #cd0a0ab3 !important;
}

.langing_cards_main .updates__cont {
   font-size: 14px;
   font-weight: 500;
   color: #b3b2b2;
}

.langing_cards_main .updates__cont_amount {
   display: inline-block !important;
   font-weight: 600;
   color: var(--color-default);
}

.langing_cards_main .wrap:after {
   display: block;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 10px;
   content: "";
}

.langing_cards_main_title svg {
   color: #d7d7d7;
   /* margin-left: 5px; */
}

.MuiSvgIcon-root-19 {
   fill: currentColor;
   width: 1em;
   height: 1em;
   display: inline-block;
   font-size: 24px;
   transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   user-select: none;
   flex-shrink: 0;
}

.langing_cards_main_title i img {
   width: 25px;
   margin-right: 5px;
}

@media screen and (max-width: 575px) {
   /* .c-dashboardInfo {
        max-width: 350px;
        margin: auto;
        margin-bottom: 20px;
    } */
}

@media screen and (max-width: 600px) {
   .langing_cards_main {
      width: 50% !important;
   }

   .langing_cards_main:nth-child(odd) .wrap {
      border-bottom: 1px solid #ebe8e8;
      border-right: 1px solid #ebe8e8;
   }

   .langing_cards_main:nth-child(even) .wrap {
      border-bottom: 1px solid #ebe8e8;
      border-right: none;
   }

   /* .c-dashboardInfo:last-child .wrap {
        border-bottom: none;
    } */

   .langing_cards_main {
      margin: 0;
      padding: 0;
   }

   .langing_cards_main .wrap {
      padding: 30px 10px;
   }
}

/* testimonial cards desing */
.test_wrapper {
   padding-top: 50px;
   background-color: transparent;
   padding-left: 10px;
   padding-right: 10px;
}

.tesi_card_cont {
   border-top: 4px solid var(--color-primary);
   background-color: aliceblue;
   text-align: center;
   padding: 24px 20px 5px 20px;
   border-radius: 0 0 10px 10px;
   height: 325px;
   width: 360px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.investor__testimonial .tesi_card_cont {
   height: auto;
}
.testiCards_arrow .slick-prev {
   left: -25px;
}
/* for dashboard section */
.testi-video-inner-2 .tesi_card_cont {
   background-color: #fff;
   width: auto;
   padding: 24px 25px 5px 25px;
}

.testi-video-inner-2 .tesi_card_cont h3 {
   margin-top: 12px;
}

@media screen and (max-width: 1200px) {
   .xcontent .tesi_card_cont {
      height: 372px !important;
   }
}
@media screen and (min-width: 1455px) {
   .testi-video-inner-2 .tesi_card_cont {
      /* height: 355px */
      height: 325px !important;
   }
}

@media screen and (min-width: 1700px) {
   .testi-video-inner-2 .tesi_card_cont {
      /* height: 355px */
      height: 380px !important;
   }
}

@media screen and (min-width: 1314px) {
   .testi-video-inner-2 .tesi_card_cont {
      /* height: 355px */
      height: 396px;
   }
}

@media screen and (max-width: 1314px) {
   .testi-video-inner-2 .tesi_card_cont {
      height: 395px;
      width: auto;
   }
}

/* @media screen and (max-width: 1135px) {
    .testi-video-inner-2 .tesi_card_cont {
        height: 320px;
        width: auto;
    }
} */
@media screen and (max-width: 1245px) {
   .ladingpageTesti .tesi_card_cont {
      height: auto;
      width: auto;
      max-width: 450px;
   }
}

/* ends for dashboard section */
@media screen and (max-width: 1200px) {
   .tesi_card_cont {
      height: 325px;
      width: 350px;
   }
}

@media screen and (max-width: 780px) {
   .tesi_card_cont {
      height: auto;
      width: auto;
      max-width: 450px;
   }
}

.quotes_mark {
   left: 50%;
   top: -25px;
   background-color: white;
   border-radius: 50%;
   width: 45px;
   height: 45px;
   display: flex;
   justify-content: center;
   text-align: center;
   align-items: center;
   transform: translateX(-50%);
   box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.quotes_mark i {
   font-size: 22px;
   color: var(--color-primary);
   /* color: #eb2024; */
}

.test_wrapper .testi_content h3 {
   color: var(--color-primary);
   font-size: 18px;
}

.test_wrapper .testi_content h4 {
   color: var(--color-secondary);
   font-size: 14px;
   font-weight: 500;
}

.test_wrapper .testi_content p {
   text-align: justify;
}

.testimonials .slick-prev:before,
.testimonials .slick-next:before {
   color: #fff !important;
}

.testimonials .slick-dots li.slick-active button:before {
   color: white !important;
}

.testimonials .slick-dots li button:before {
   color: white !important;
}

/* investor dashboard css */
.inv_card_cont_wrapper .sec-title_investor {
   font-weight: 500;
}

.inv_card_cont_wrapper_2 .table_calc_cont {
   border: 1px solid var(--color-lightGray);
   border-radius: 8px;
   padding: 10px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
   background-color: #fff;
   height: 100%;
}

.inv_card_cont_wrapper_2 .tableAmntPara {
   /* border-bottom: 1px solid var(--color-lightGray); */
   padding-bottom: 10px;
}

.inv_card_cont_wrapper_2 .tableAmntPara p {
   margin: 0;
}

.inv_card_cont_wrapper_2 .table {
   border: 1px solid var(--color-lightGray);
}

@media screen and (max-width: 1160px) {
   .three_grid_table {
      width: 50%;
   }
}

.inv_card_cont_wrapper_2 .amntInptSpan {
   display: inline-block;
   position: relative;
}

.inv_card_cont_wrapper_2 .amntInptSpan .amount_fillup {
   height: 20px;
   width: 80px !important;
   /* text-align: center; */
   border: none;
   outline: none;
   border-bottom: 1px solid var(--color-default);
   padding-left: 12px;
   color: var(--color-grayDark);
   margin-left: 2px;
}

.inv_card_cont_wrapper_2 .amntInptSpan .rupeeInptSIgn {
   position: absolute;
   top: 0;
   left: 1px;
   color: var(--color-grayDark);
   font-size: 18px;
}

/* my client cards design */
.radius-10 {
   border-radius: 10px !important;
}

.card_border-1 {
   border-left: 5px solid #0dcaf0 !important;
}

.card_border-2 {
   border-left: 5px solid #fd3550 !important;
}

.card_border-3 {
   border-left: 5px solid #15ca20 !important;
}

.card_border-4 {
   border-left: 5px solid #ffc107 !important;
}

.card_text_clr_1 {
   color: #0dcaf0;
}
.card_text_clr_2 {
   color: #fd3550;
}
.card_text_clr_3 {
   color: #15ca20;
}
.card_text_clr_4 {
   color: #ffc107;
}

.client__card {
   position: relative;
   display: flex;
   flex-direction: column;
   min-width: 0;
   word-wrap: break-word;
   background-color: #fff;
   background-clip: border-box;
   border: 0px solid rgba(0, 0, 0, 0);
   border-radius: 0.25rem;
   margin-bottom: 1.5rem;
   box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
      0 2px 6px 0 rgb(206 206 238 / 54%) !important;
}
.client__card h4 {
   font-weight: 900;
}
.client__card .client_card_title {
   font-weight: 600;
}

.client__card .widgets-icons-2 {
   width: 45px;
   height: 45px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #ededed;
   font-size: 22px;
   border-radius: 10px;
}

.rounded-circle {
   border-radius: 50% !important;
}

.text-white {
   color: #fff !important;
}

.ms-auto {
   margin-left: auto !important;
}

.bg-gradient-1 {
   background: #17ead9;
   background: -webkit-linear-gradient(45deg, #17ead9, #6078ea) !important;
   background: linear-gradient(45deg, #17ead9, #6078ea) !important;
}

.bg-gradient-2 {
   background: #f54ea2;
   background: -webkit-linear-gradient(45deg, #f54ea2, #ff7676) !important;
   background: linear-gradient(45deg, #f54ea2, #ff7676) !important;
}

.bg-gradient-3 {
   background: #00b09b;
   background: -webkit-linear-gradient(45deg, #00b09b, #96c93d) !important;
   background: linear-gradient(45deg, #00b09b, #96c93d) !important;
}
.bg-gradient-4 {
   background: #ffdf40;
   background: -webkit-linear-gradient(45deg, #ffdf40, #ff8359) !important;
   background: linear-gradient(45deg, #ffdf40, #ff8359) !important;
}

/* client table */
.client_table_container {
   background-color: #fff;
   border-radius: 8px;
   box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
      0 2px 6px 0 rgb(206 206 238 / 54%) !important;
   padding: 15px !important;
}
.client_table_container h2 {
   padding: 7px 12px;
   /* padding-left: 10px; */
   font-size: 18px;
   margin-bottom: 0;
}
.client__table {
   margin-top: 15px;
   border-top: 1px solid #eee;
   /* text-align: center; */
}
.client__table tr {
   font-size: 14px;
   font-weight: 500;
   color: black;
}

@media screen and (min-width: 768px) {
   .table__row__wrapp {
      padding: 0 12px;
   }
   .client_table_container {
      padding: 12px;
   }
   .client_table_container h2 {
      padding-left: 0px;
   }
   
}

@media screen and (max-width: 770px) {
   .hover__card:last-of-type{
      margin-bottom: 214px;
   }
}