/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap");

:root {
  /* --font-default: 'Red Hat Display', sans-serif;
  --font-primary: 'Red Hat Display', sans-serif;
  --font-secondary: 'Red Hat Display', sans-serif; */
  --font-default: "Source Sans Pro", sans-serif;
  --font-primary: "Source Sans Pro", sans-serif;
  --font-secondary: "Source Sans Pro", sans-serif;
}

/* Colors */
:root {
  --color-default: #0a0d13;
  --color-primary: #00ad51;
  --color-secondary: #0e1d34;
  --color-logoBlue: #2492eb;
  --color-darkBlue: #135693;
  --color-lightGray: #ebe8e8;
  --color-grayDark: #857b7b;
}

.form-slider-container button {
  border-radius: 20px;
  border: 1px solid #ff4550;
  background-color: #ff4550;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.form-slider-container button:active {
  transform: scale(0.95);
}

.form-slider-container button:focus {
  outline: none;
}

.form-slider-container button.ghost {
  background-color: white;
  border-color: #ffffff;
  color: #ff4b2b;
  padding: 12px 35px !important;
  font-size: 14px !important;
}

.form-slider-container form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12px 25px;
  height: 100%;
  text-align: center;
}

.sign-up-container #signinForm {
  justify-content: flex-start !important;
  padding: 10px 30px;
  overflow-y: auto;
  height: inherit;
}

.form-slider-container input,
.form-slider-container select {
  /* #search_input { */
  background-color: #eee;
  border: none;
  padding: 0px 15px;
  margin: 5px 0;
  width: 100%;
  height: 40px;
  color: #7a7a7a;
  border-radius: 3px;
}

.form-slider-container input:focus,
.form-slider-container select:focus {
  outline: 1px solid var(--color-logoBlue);
  border: none;
}

/* .form-slider-container select {
	padding-left: 11px;
} */

.multiSelectInput {
  width: 100%;
}

.searchWrapper {
  border: none;
  padding: 0;
}

/* autoComplete inpit */
/* .autoCompleteCity {
	margin: 5px 0;
} */
.autoCompleteCity .css-1dimb5e-singleValue {
  text-align: left !important;
  margin-left: 6px !important;
}

.autoCompleteCity .css-1jqq78o-placeholder {
  margin-left: 6px !important;
}

.autoCompleteCity .select__menu {
  /* min-height: 48px; */
  max-height: 125px;
  overflow-y: auto;
  margin: 0;
  margin-top: 0 !important;
}

.form-slider-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
  /* max-height: 585px; */
  transition: all 0.6s ease-in-out;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #ff416c;
  background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
  background: linear-gradient(to right, #ff4b2b, #ff416c);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

i.fa-facebook-f {
  background-color: unset !important;
}

.optionListContainer {
  position: unset;
}

/* multiSelect dropdown */
.signinForm .select__control {
  background: #eee !important;
  min-height: 40px !important;
  border: none !important;
}

.css-1jqq78o-placeholder {
  text-align: left !important;
  /* margin-left: 8px !important; */
}

/* ===========create Lead Styling======== */
.CreatLeadFormWrapper {
  height: 515px;
  overflow: auto !important;
}
.createLeadMultiSelect .css-1jqq78o-placeholder {
  margin-left: 0px !important;
}
.createLeadMultiSelect .select__menu {
  position: relative !important;
  height: 120px;
}
.createLeadMultiSelect .select__menu div {
  cursor: pointer !important;
}
.createLeadMultiSelect .css-qbdosj-Input {
  padding-left: 0px !important;
}

.select__menu {
  text-align: left !important;
}

.form-slider-container .css-qbdosj-Input {
  margin: 0 !important;
}

.css-qbdosj-Input {
  padding-left: 7px !important;
  height: 40px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.css-3w2yfm-ValueContainer {
  padding: 0 !important;
}

.css-13cymwt-control {
  border: none !important;
}

.signinForm .css-hlgwow {
  min-height: 40px !important;
  padding-left: 13px !important;
}

.select__input {
  height: 40px !important;
}

.select__input-container {
  height: 40px !important;
}

.css-1p3m7a8-multiValue {
  background: white !important;
}

.css-19bb58m {
  margin: 2px 0;
  padding-top: 0 !important;
}
.form-check-input.remember-check-box {
  border-radius: 50%;
  padding: 0;
  height: 20px;
  border: 1px solid #00ad51;
}
@media screen and (min-width: 660px) {
  .mobile-form-heading {
    display: none !important;
  }
}
@media screen and (max-width: 350px) {
  .otp__form__responheight {
    width: 380px;
    min-height: 370px !important;
  }
}
@media screen and (max-width: 660px) {
  .form-slider-container {
    width: 380px;
    min-height: 340px;
  }

  .sign-up-container {
    opacity: 1;
  }

  .overlay-container {
    display: none;
  }

  .sign-up-container {
    width: 100%;
  }

  .sign-in-container {
    width: 100%;
  }

  .form-heading {
    display: none;
  }

  .mobile-form-heading h3 {
    text-transform: capitalize;
    font-size: 25px;
    color: var(--color-default);
    padding-bottom: 10px;
    background-color: #fff;
    border-radius: 8px;
    padding: 8px;
  }

  .mobile-form-heading {
    margin-bottom: 20px;
  }

  .mobile-form-heading h3.si-active {
    color: var(--color-default);
    border-bottom: 3px solid var(--color-logoBlue);
  }

  .optMobile {
    padding-left: 1px;
    padding-right: 1px;
  }

  .css-13cymwt-control {
    margin-bottom: 4px;
  }

  #otp-input {
    margin: 11px 0 !important;
  }

  .form-container form {
    overflow: hidden;
  }

  .form-slider-container form {
    justify-content: flex-start;
    overflow: hidden;
  }
}

.form-container form {
  overflow: hidden;
}

#otp-form {
  padding: 0 1px !important;
}

/* form by scan */
.formByScan_container {
  width: 400px;
  padding: 10px 25px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: all 0.3s;
  /* display: flex; */
}

.fixing-position {
  position: relative;
}

.formByScan_container input,
.formByScan_container select {
  background-color: #eee;
  border: none;
  padding: 0px 15px !important;
  margin-bottom: 10px;
  width: 100%;
  height: 40px !important;
  color: #7a7a7a;
  border-radius: 3px;
  box-shadow: none !important;
}

.formByScan_container .form-check-input {
  height: 16px !important;
  width: 16px !important;
  padding: 0 !important;
  /* background-color: unset; */
  border: 1px solid var(--color-primary);
  border-radius: 50% !important;
}

.usertypeCheck_label {
  font-size: 14px;
  color: var(--color-default);
  font-weight: 500;
}

.formByScan_container input:focus,
.formByScan_container select:focus {
  outline: 1px solid var(--color-logoBlue);
}

.formByScan_container .select__control {
  min-height: 40px !important;
  background-color: #eee;
  padding-left: 15px !important;
  box-shadow: none !important;
  margin-top: -4px !important;
  margin-bottom: 9px !important;
}

.formByScan_container .css-qbdosj-Input {
  margin: 0 !important;
  /* height: 40px !important; */
}

.formByScan_container .css-1fdsijx-ValueContainer {
  padding: 0;
}
.lead-client{
  justify-content: space-between;
}
/* .formByScan_container button { */
.formByScan_container .subBtn {
  border-radius: 25px;
  text-align: center;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 13px;
  /* font-weight: bold; */
  padding: 10px 25px;
  /* letter-spacing: 1px; */
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.formByScan_container .select__input {
  padding: 0 !important;
}

@media screen and (max-width: 550px) {
  .formByScan_container {
    width: 90vw;
  }
  .checkin-timer{
    display: none;
  }
}

/* citySearchInput */
.citySuggestion {
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  left: 0;
  background-color: #fff;
  width: 100%;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
  text-align: left;
  max-height: 140px;
  overflow-y: auto;
  z-index: 10;
}

.citySuggestion li {
  padding: 10px 15px;
  border-bottom: 1px solid var(--color-lightGray);
}

.citySuggestion li:hover {
  background-color: var(--color-logoBlue);
  cursor: pointer;
  color: white;
}

.citySuggestion li:last-child {
  border: none;
}

.citySearchNotfound {
  position: absolute;
  z-index: 10;
  background: #fff;
  width: 100%;
  height: 48px;
  text-align: left;
  padding: 10px 15px;
  border: 1px solid var(--color-lightGray);
  border-radius: 5px;
}

/* logout confirmation */

/* -------------------------------- 

xpopup 

-------------------------------- */
.cd-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(94, 110, 141, 0.9);
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  z-index: 99999;
}

.cd-popup.is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}

.cd-popup-container {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: 4em auto;
  padding-top: 40px;
  background: #fff;
  border-radius: 0.25em 0.25em 0.4em 0.4em;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  transform: translateY(-40px);
  /* Force Hardware Acceleration in WebKit */
  -webkit-backface-visibility: visible;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.cd-popup-container h5 {
  padding: 30px 20px;
}

.cd-popup-container .cd-buttons {
  padding: 0;
  margin: 0;
}

.cd-popup .popupImg {
  width: 120px;
  height:  100px;
}

.cd-popup-container .cd-buttons:after {
  content: "";
  display: table;
  clear: both;
}

.cd-popup-container .cd-buttons li {
  float: left;
  width: 50%;
  list-style: none;
}

.cd-popup-container .cd-buttons a {
  display: block;
  height: 60px;
  line-height: 60px;
  text-transform: uppercase;
  color: #fff;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.cd-popup-container .cd-buttons li:first-child a {
  background: #fc7169;
  border-radius: 0 0 0 0.25em;
}

.no-touch .cd-popup-container .cd-buttons li:first-child a:hover {
  background-color: #fc8982;
}

.cd-popup-container .cd-buttons li:last-child a {
  background: #b6bece;
  border-radius: 0 0 0.25em 0;
}

.no-touch .cd-popup-container .cd-buttons li:last-child a:hover {
  background-color: #c5ccd8;
}

.cd-popup-container .cd-popup-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
}

.cd-popup-container .cd-popup-close::before,
.cd-popup-container .cd-popup-close::after {
  content: "";
  position: absolute;
  top: 12px;
  width: 14px;
  height: 3px;
  background-color: #8f9cb5;
}

.cd-popup-container .cd-popup-close::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 8px;
}

.cd-popup-container .cd-popup-close::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 8px;
}

.is-visible .cd-popup-container {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

@media only screen and (min-width: 1170px) {
  .cd-popup-container {
    margin: 8em auto;
  }
}

.quickLogForm {
  height: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quickLogForm input {
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  width: min-content;
  height: min-content;
}

.quickLogForm input[type="text"] {
  visibility: hidden;
  opacity: 0;
  height: 0 !important;
  width: 0 !important;
}

/* new form by scan styling */
.scanNewForm {
  /* min-height: 500px; */
  min-height: 360px;
}

.scanNewForm form {
  justify-content: flex-start;
  align-items: start;
}

.form_by_scan .numb_opt input {
  padding-left: 50px;
  border-left: 2px solid var(--color-primary);
  font-size: 18px;
  /* border-right: 2px solid var(--color-primary); */
}

input#optMobile {
  height: 50px !important;
}

.form_by_scan .numb_opt input::placeholder {
  font-size: 18px;
}

.form_by_scan .country_code {
  font-size: 18px;
  left: 8px;
  top: 18%;
  color: #7a7a7a;
  padding-top: 6px;
  padding-right: 8px;
  padding-bottom: 8px;
  border-right: 2px solid rgb(201, 198, 198);
}

.form_by_scan .otp_send_btn {
  width: 100%;
  padding: 10px;
}

.para_tags::before {
  content: "";
  position: absolute;
  top: 0;
  left: -25px;
  width: 4px;
  height: 100%;
  background-color: var(--color-logoBlue);
}

.form_by_scan .para_tags p {
  /* color: #7a7a7a; */
  font-size: 18px;
  margin-bottom: 0px;
}

.otp__para span,
.otp__para p {
  font-size: 18px;
}

.otp__para span {
  font-size: 16px;
  color: #ff4265;
  font-weight: 600;
  cursor: pointer;
}

.resend__otp {
  color: #ff4265;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.otp__form_slide {
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.num__form_slide {
  padding-top: 10px;
  transition: 0.3s;
}

.forms__container {
  transition: 0.3s;
}

.forms__container.slide .otp__form_slide {
  opacity: 1;
  visibility: visible;
}

.forms__container.slide {
  transform: translateY(-51%);
}

.forms__container.slide .num__form_slide {
  opacity: 0;
  visibility: hidden;
}

.scanNewForm .img_wrapper {
  background-color: #eefcff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.scanNewForm .img_wrapper img {
  /* min-height: 180px; */
  /* width: 100%; */
  max-height: 150px;
}

/* OTP input desing */
.otp-field {
  display: flex;
}

.otp-field input {
  width: 38px !important;
  height: 38px;
  font-size: 20px;
  padding: 5px;
  text-align: center;
  border-radius: 5px !important;
  margin: 2px;
  border: 1px solid var(--color-logoBlue);
  /* outline: 1px solid var(--color-logoBlue); */
  background: #fff;
  font-weight: bold;
  color: black;
  outline: none;
  transition: all 0.1s;
}
.otp-field.validationErr input {
  border-color: rgb(214, 15, 15);
}

/* .otp-field input:focus {
	border: 2px solid #a527ff;
	box-shadow: 0 0 2px 2px #a527ff6a;
} */

/* .disabled {
	opacity: 0.5;
} */

/* ===========registerNowInputs=========== */
.registerNowForm .regFileInputs {
  display: none;
  visibility: hidden;
}

.fileInputWrapper {
  margin-bottom: 5px;
}

.registerNowForm input {
  margin-bottom: 4px;
  height: 26px !important;
}

.registerNowForm input::placeholder {
  font-size: 14px;
}

.registerNowForm label {
  font-size: 14px;
  margin-left: 2px;
  padding: 0px 0px;
  font-weight: 400;
}

.registerNowForm .dummyInput {
  width: 100%;
  height: 28px;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  border-radius: 8px;
}

.registerNowForm .dummyInput .fileIcon {
  /* padding: 5px 15px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  color: #fff;
  height: 100%;
}
.opt_input {
  position: relative;
  /* width: fit-content; */
}
.verify.fileIcon {
  /* padding: 5px 15px; */
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  color: #fff;
  height: 30px;
  top: 0;
  right: 0;
}

.registerNowForm .dummyInput .dummyInputPlaceholder {
  color: var(--color-grayDark);
  font-size: 14px;
}

.registerNowForm .termsLabel {
  font-size: 14px;
}

.registerNowForm button {
  padding: 8px 40px;
}

/* .registerNowForm .regNowForm {
	max-height: 505px;
} */
@media (max-width: 450px){
  .cd-popup .popupImg {
    width: 70px;
    height: 90px;
  }
}