/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap');

:root {
  /* --font-default: 'Red Hat Display', sans-serif;
  --font-primary: 'Red Hat Display', sans-serif;
  --font-secondary: 'Red Hat Display', sans-serif; */
  --font-default: 'Source Sans Pro', sans-serif;
  --font-primary: 'Source Sans Pro', sans-serif;
  --font-secondary: 'Source Sans Pro', sans-serif;
}


:root {
  --color-default: #0a0d13;
  --color-primary: #00ad51;
  --color-secondary: #0e1d34;
  --color-logoBlue: #2492eb;
  --color-lightGray: #ebe8e8;
  --color-grayDark: #857b7b;
}


.section-heading .section-title {
  position: relative;
  text-align: center;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.section-heading .section-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50px;
  height: 4px;
  background: var(--color-primary);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.pb-minus-70 {
  padding-bottom: 70px;
}

/* ---------------------------------------------------------------- */
/* 04.Buttons                                                   
  /* ---------------------------------------------------------------- */
.default-button {
  border-radius: 5px;
  display: inline-block;
  background-color: var(--color-primary);
  border: none;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 18px 36px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  color: #fff !important;
}

.default-button:after {
  display: none;
  content: "";
  position: absolute;
  border-radius: 50%;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-color: rgba(0, 0, 0, 0.247);
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  top: 50%;
  left: 50%;
  -webkit-animation: ripple 1s;
  animation: ripple 1s;
  opacity: 0;
}

.default-button:focus:not(:active)::after {
  display: block;
}

.popup-btn {
  border-radius: 5px;
  display: inline-block;
  border: none;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 12px 30px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  color: #fff !important;
}

.popup-btn:after {
  display: none;
  content: "";
  position: absolute;
  border-radius: 50%;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-color: rgba(0, 0, 0, 0.247);
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  top: 50%;
  left: 50%;
  -webkit-animation: ripple 1s;
  animation: ripple 1s;
  opacity: 0;
}

.popup-btn:focus:not(:active)::after {
  display: block;
}

.popup-btn.danger {
  background-color: #f27474;
}

.popup-btn.success {
  background-color: var(--color-primary);
}

.popup-btn.agree {
  background-color: var(--color-primary);
}

@-webkit-keyframes ripple {
  from {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(10);
    transform: scale(10);
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(10);
    transform: scale(10);
  }
}

/* ---------------------------------------------------------------- */
/* 05.Contact Form                                                   
  /* ---------------------------------------------------------------- */
.contact-form-wrap {
  background-color: #fff;
  padding: 20px 30px;
  max-width: 768px;
  border-radius: 10px;
  -webkit-box-shadow: 0 23px 49px 0 rgba(61, 82, 96, 0.15);
  box-shadow: 0 23px 49px 0 rgba(61, 82, 96, 0.15);
}

.contact-form-wrap .inquiry-form-group {
  position: relative;
  margin-bottom: 18px;
}

.contact-form-wrap .inquiry-form-group>i {
  left: 14px;
  top: 14px;
  width: 32px;
  height: 32px;
  position: absolute;
  text-align: center;
  line-height: 32px;
  border-radius: 50%;
  display: inline-block;
  pointer-events: none;
  color: var(--color-primary);
  background: rgba(10, 192, 131, 0.2);
  font-size: 12px;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
}

.contact-form-wrap .inquiry-form-group .form-control {
  height: 60px;
  color: #000;
  outline: none;
  /* background-color: #fff; */
  padding: 0 55px;
  padding-right: 15px;
  font-size: 15px;
  border: none;
  border-radius: 5px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-box-shadow: 0 5px 20px 0 rgba(61, 82, 96, 0.15);
  box-shadow: 0 5px 20px 0 rgba(61, 82, 96, 0.15);
}

@media screen and (max-width: 576px) {
  .contact-form-wrap .inquiry-form-group .form-control {
    font-size: 14px;
  }
}

.contact-form-wrap .inquiry-form-group .form-control:focus {
  outline: none;
  border: none;
  color: #000;
  -webkit-box-shadow: 0 23px 49px 0 rgba(61, 82, 96, 0.15);
  box-shadow: 0 23px 49px 0 rgba(61, 82, 96, 0.15);
}

.contact-form-wrap .inquiry-form-group #txtInput {
  padding-left: 90px;
}

.contact-form-wrap .inquiry-form-group #txtCaptchaSpan {
  position: absolute;
  top: 50%;
  height: 60px;
  padding-left: 14px;
  padding-right: 14px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--color-primary);
  color: #fff;
  border-radius: 5px 0 0 5px;
}

.contact-form-wrap .inquiry-form-group textarea.form-control {
  height: auto;
  padding-top: 20px;
}

.contact-form-wrap .empty-form span,
.contact-form-wrap .email-invalid span,
.contact-form-wrap .phone-invalid span,
.contact-form-wrap .terms-alert span,
.contact-form-wrap .subject-alert span,
.contact-form-wrap .security-alert span {
  display: block;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #721c24;
  background: rgba(242, 116, 116, 0.2);
}

#error_message {
  margin-top: 30px;
  display: block;
  padding: 20px;
  border-radius: 5px;
  word-break: break-word;
  background: rgba(242, 116, 116, 0.2);
  color: #721c24;
}

.custom-modal .modal-header {
  text-align: center;
  padding: 20px;
}

.custom-modal .modal-header h5,
.custom-modal .modal-header button {
  color: #fff;
}

.custom-modal .modal-header button {
  background: transparent;
  outline: none;
  border: none;
}

.custom-modal .modal-header span {
  font-size: 20px;
}

.custom-modal .modal-header.danger {
  background: #f27474;
  color: #fff;
}

.custom-modal .modal-header.success {
  background: var(--color-primary);
  color: #fff;
}

.custom-modal .modal-header.terms {
  background: #07f;
  color: #fff;
}

.custom-modal .modal-body {
  padding: 30px;
}

.custom-modal .modal-body .form-popup-inner {
  text-align: center;
}

.custom-modal .modal-body .form-popup-inner .form-icon {
  margin-bottom: 30px;
}

.custom-modal .modal-body .form-popup-inner .form-icon svg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.custom-modal .modal-body .form-popup-inner .form-icon.danger svg {
  fill: #f27474;
  stroke: #f27474;
  background: rgba(242, 116, 116, 0.1);
}

.custom-modal .modal-body .form-popup-inner .form-icon.success svg {
  fill: var(--color-primary);
  stroke: var(--color-primary);
  background: rgba(10, 192, 131, 0.1);
}

.custom-modal .modal-footer {
  padding: 20px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}

.path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dashCheck 0.9s 0.35s ease-in-out forwards;
  animation: dashCheck 0.9s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dashCheck {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dashCheck {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

.custom-control {
  padding: 0;
}

.custom-control span {
  vertical-align: middle;
  margin-left: 14px;
  color: #000;
}

.custom-control span a {
  color: var(--color-primary);
  margin-left: 5px;
  display: inline-block;
}

@media screen and (max-width: 576px) {
  .custom-control span {
    font-size: 14px;
  }
}

.custom-control .custom-control-label {
  margin-left: 0;
  width: 32px;
  height: 32px;
  outline: none;
  display: block;
  position: relative;
  text-align: center;
  line-height: 32px;
}

.custom-control .custom-control-label:before {
  top: 0;
  left: 0;
  content: "";
  width: 32px;
  height: 32px;
  cursor: pointer;
  text-align: center;
  line-height: 32px;
  position: absolute;
  border: none !important;
  pointer-events: initial;
  border-radius: 50% !important;
  display: inline-block;
  color: var(--color-primary);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: rgba(10, 192, 131, 0.2);
  font-size: 12px;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
}

.custom-control-input:checked~.custom-control-label::before,
.custom-control-label:active::before,
.custom-control-input:not(:disabled):active~.custom-control-label::before {
  border: none;
  content: "\f00c";
  color: var(--color-primary);
  background: rgba(10, 192, 131, 0.2);
}

.custom-control-label::after {
  display: none;
}

.custom-control-input {
  display: none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border: none;
}

.form-validate-icons {
  top: 0;
  right: 14px;
  height: 60px;
  position: absolute;
  line-height: 60px;
}

.form-validate-icons span {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.form-validate-icons span:before {
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
}

.form-validate-icons span.error {
  opacity: 1;
  visibility: visible;
}

.form-validate-icons span.error:before {
  content: "\f00d";
  color: #f27474;
  background: rgba(242, 116, 116, 0.2);
}

.form-validate-icons span.success {
  opacity: 1;
  visibility: visible;
}

.form-validate-icons span.success:before {
  content: "\f00c";
  color: var(--color-primary);
  background: rgba(10, 192, 131, 0.2);
}

.custom-select-wrapper {
  position: relative;
}

.custom-select-wrapper select,
.custom-select-wrapper .select-hide {
  display: none;
}

.custom-select-wrapper .select-selected {
  border-radius: 5px;
  outline: none;
  padding: 18px 60px;
  font-size: 15px;
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-color: #fff;
  color: #677294 !important;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(61, 82, 96, 0.15);
  box-shadow: 0px 5px 20px 0px rgba(61, 82, 96, 0.15);
}

.custom-select-wrapper .select-selected:focus {
  outline: none;
  border: none;
  -webkit-box-shadow: 0px 23px 49px 0px rgba(61, 82, 96, 0.15);
  box-shadow: 0px 23px 49px 0px rgba(61, 82, 96, 0.15);
}

.custom-select-wrapper .select-selected:after {
  right: 14px;
  top: 14px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  content: "\f078";
  position: absolute;
  pointer-events: none;
  display: inline-block;
  color: var(--color-primary);
  background: rgba(10, 192, 131, 0.2);
  font-size: 14px;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
}

.custom-select-wrapper .select-selected.select-arrow-active:after {
  content: "\f077";
}

.custom-select-wrapper .select-items {
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  margin-top: 10px;
  position: absolute;
  background-color: #fff;
  -webkit-box-shadow: 0px 23px 49px 0px rgba(61, 82, 96, 0.15);
  box-shadow: 0px 23px 49px 0px rgba(61, 82, 96, 0.15);
}

.custom-select-wrapper .select-items .same-as-selected {
  background-color: var(--color-primary);
  border-bottom-color: var(--color-primary);
  color: #fff !important;
}

.custom-select-wrapper .select-items .same-as-selected:after {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

.custom-select-wrapper .select-items div {
  color: #000;
  outline: none;
  width: 100%;
  display: block;
  padding: 18px 20px 18px 60px;
  font-size: 15px;
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

@media screen and (max-width: 576px) {
  .custom-select-wrapper .select-items div {
    font-size: 14px;
  }
}

.custom-select-wrapper .select-items div:focus {
  outline: none;
  border: none;
  -webkit-box-shadow: 0 23px 49px 0 rgba(61, 82, 96, 0.15);
  box-shadow: 0 23px 49px 0 rgba(61, 82, 96, 0.15);
}

.custom-select-wrapper .select-items div:last-child {
  border-bottom: 0;
}

.custom-select-wrapper .select-items div:after {
  content: "\f036";
  left: 14px;
  top: 14px;
  width: 32px;
  height: 32px;
  z-index: 14;
  position: absolute;
  text-align: center;
  line-height: 32px;
  border-radius: 50%;
  display: inline-block;
  color: var(--color-primary);
  background: rgba(10, 192, 131, 0.2);
  font-size: 12px;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
}

.custom-select-wrapper .select-items div:hover {
  background-color: var(--color-primary);
  border-bottom-color: var(--color-primary);
  color: #fff;
}

.custom-select-wrapper .select-items div:hover:after {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

#colorOptionsSidebar {
  top: 50%;
  right: 0;
  z-index: 7777;
  line-height: 1;
  position: fixed;
  text-align: left;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#colorOptionsSidebar #colorOptionsSidebarToggle {
  top: 0;
  left: -52px;
  position: absolute;
  padding: 16px;
  font-size: 20px;
  color: #fff;
  border: none;
  background: var(--color-primary);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#colorOptionsSidebar .color-options-wrap {
  padding: 15px 6px 10px 15px;
  max-width: 219px;
  background: #fff;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-shadow: 0 23px 49px 0 rgba(61, 82, 96, 0.15);
  box-shadow: 0 23px 49px 0 rgba(61, 82, 96, 0.15);
  transition: all 0.4s ease;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

#colorOptionsSidebar .color-options-wrap.active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

#colorOptionsSidebar span {
  width: 38px;
  height: 38px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  margin: 3px;
  display: inline-block;
}

#colorOptionsSidebar span.default {
  background: var(--color-primary);
}

#colorOptionsSidebar span.blue {
  background: #0652DD;
}

#colorOptionsSidebar span.red {
  background: #EA2027;
}

#colorOptionsSidebar span.yellow {
  background: #F79F1F;
}

#colorOptionsSidebar span.green {
  background: #6ab04c;
}

#colorOptionsSidebar span.pink {
  background: #FC427B;
}

#colorOptionsSidebar span.turquose {
  background: #01a3a4;
}

#colorOptionsSidebar span.purple {
  background: #B33771;
}

#colorOptionsSidebar span.blue2 {
  background: #2e86de;
}

#colorOptionsSidebar span.orange {
  background: #ff7500;
}

#colorOptionsSidebar span.magenta {
  background: #6F1E51;
}

#colorOptionsSidebar span.orange2 {
  background: #ff793f;
}

#colorOptionsSidebar span.active:after {
  content: "\f00c";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  position: absolute;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(21, 21, 21, 0.2);
  font-family: "Font Awesome 5 Free";
}

#rtlSidebar {
  top: 50%;
  right: 0;
  margin-top: -115px;
  z-index: 7777;
  line-height: 1;
  position: fixed;
  text-align: left;
  display: inline-block;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#rtlSidebar.ltr-mode {
  right: 0;
}

#rtlSidebar.rtl-mode {
  left: 0;
}

#rtlSidebar #rtlToggle {
  padding: 16px 12.5px;
  font-size: 16px;
  color: #fff;
  border: none;
}

#darkSidebar {
  top: 50%;
  right: 0;
  margin-top: -180px;
  z-index: 7777;
  line-height: 1;
  position: fixed;
  text-align: left;
  display: inline-block;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#darkSidebar #darkToggle {
  padding: 16px;
  font-size: 20px;
  color: #fff;
  border: none;
}

body.dark-mode {
  background: #151515;
}

body.dark-mode .footer {
  background: rgba(255, 255, 255, 0.02);
}

body.dark-mode .section-heading .section-title {
  color: #fff;
}

body.dark-mode .custom-modal {
  background: #151515;
}

body.dark-mode .custom-modal .modal-header {
  text-align: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-bottom: none;
}

body.dark-mode .custom-modal .modal-header h5,
body.dark-mode .custom-modal .modal-header button {
  color: #fff;
}

body.dark-mode .custom-modal .modal-content {
  background: #151515;
}

body.dark-mode .custom-modal .modal-content p {
  color: #fff;
  opacity: 0.75;
}

body.dark-mode .custom-modal .modal-footer {
  border-top: none;
  background: rgba(255, 255, 255, 0.05);
}

body.dark-mode .custom-modal .modal-body {
  padding: 20px;
  background: #151515;
}

body.dark-mode .custom-modal .modal-body .form-popup-inner {
  text-align: center;
}

body.dark-mode .custom-modal .modal-body .form-popup-inner .form-icon {
  margin-bottom: 30px;
}

body.dark-mode .custom-modal .modal-body .form-popup-inner .form-icon svg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

body.dark-mode .custom-modal .modal-body .form-popup-inner .form-icon.danger svg {
  fill: #fff;
  stroke: #fff;
  background: rgba(255, 255, 255, 0.1);
}

body.dark-mode .custom-modal .modal-body .form-popup-inner .form-icon.success svg {
  fill: #fff;
  stroke: #fff;
  background: rgba(255, 255, 255, 0.1);
}

body.dark-mode .custom-modal .modal-body #error_message {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

body.dark-mode .custom-control .custom-control-label::before {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

body.dark-mode .custom-control span {
  color: #fff;
}

body.dark-mode .custom-control-input:checked~.custom-control-label::before,
body.dark-mode .custom-control-label:active::before,
body.dark-mode .custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #fff;
  background: var(--color-primary);
}

body.dark-mode .contact-form-wrap {
  -webkit-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
}

body.dark-mode .contact-form-wrap .inquiry-form-group .form-control {
  color: #fff;
  background: transparent;
  -webkit-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
}

body.dark-mode .contact-form-wrap .inquiry-form-group .form-control:focus {
  -webkit-box-shadow: 0 0 0 1px var(--color-primary);
  box-shadow: 0 0 0 1px var(--color-primary);
}

body.dark-mode .contact-form-wrap .inquiry-form-group>i {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

body.dark-mode .contact-form-wrap #txtInput {
  padding-left: 90px;
}

body.dark-mode .contact-form-wrap #txtCaptchaSpan {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

body.dark-mode .contact-form-wrap .form-validate-icons span.error:before {
  color: #fff;
  background: #f27474;
}

body.dark-mode .contact-form-wrap .form-validate-icons span.success:before {
  color: #fff;
  background: var(--color-primary);
}

body.dark-mode .empty-form span,
body.dark-mode .email-invalid span,
body.dark-mode .phone-invalid span,
body.dark-mode .terms-alert span,
body.dark-mode .subject-alert span,
body.dark-mode .security-alert span {
  color: #fff;
  background: rgba(255, 255, 255, 0.05);
}

body.dark-mode .custom-select-wrapper .select-selected {
  background-color: transparent;
  color: #fff !important;
  -webkit-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
}

body.dark-mode .custom-select-wrapper .select-selected:focus {
  -webkit-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
}

body.dark-mode .custom-select-wrapper .select-selected:after {
  color: #fff;
  background: var(--color-primary);
}

body.dark-mode .custom-select-wrapper .select-items {
  background-color: #151515;
  -webkit-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
}

body.dark-mode .custom-select-wrapper .select-items .same-as-selected {
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom-color: rgba(255, 255, 255, 0.1);
  color: #fff !important;
}

body.dark-mode .custom-select-wrapper .select-items div {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

body.dark-mode .custom-select-wrapper .select-items div:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

body.dark-mode .custom-select-wrapper .select-items div:hover {
  background-color: rgba(255, 255, 255, 0.05);
  border-bottom-color: rgba(255, 255, 255, 0.05);
  color: #fff;
}

/* ---------------------------------------------------------------- */
/* 06.Features                                                   
  /* ---------------------------------------------------------------- */
.features-box {
  padding: 30px;
  overflow: hidden;
  margin-bottom: 30px;
  background: #fff;
  text-align: center;
  border-radius: 10px;
  -webkit-box-shadow: 0 6px 10px 0 rgba(61, 82, 96, 0.15);
  box-shadow: 0 6px 10px 0 rgba(61, 82, 96, 0.15);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.features-box:hover {
  -webkit-box-shadow: 0 22px 49px 0 rgba(61, 82, 96, 0.15);
  box-shadow: 0 22px 49px 0 rgba(61, 82, 96, 0.15);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.features-box .features-icon i {
  color: #fff;
  font-size: 40px;
  margin-left: 0;
  display: inline-block;
  margin-bottom: 20px;
  line-height: 100px;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background: var(--color-primary);
}
