/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap');

:root {
  /* --font-default: 'Red Hat Display', sans-serif;
  --font-primary: 'Red Hat Display', sans-serif;
  --font-secondary: 'Red Hat Display', sans-serif; */
  --font-default: 'Source Sans Pro', sans-serif;
  --font-primary: 'Source Sans Pro', sans-serif;
  --font-secondary: 'Source Sans Pro', sans-serif;
}

/* Colors */
:root {
  --color-default: #0a0d13;
  --color-primary: #00ad51;
  --color-secondary: #0e1d34;
  --color-logoBlue: #2492eb;
  --color-lightGray: #ebe8e8;
  --color-grayDark: #857b7b;
}

/* h1,h2,h3,h4,h5,h6 {
  color: var(--color-default);
} */

/* franchise tabs content */
.franchise_tab_content {
  border: 1px solid var(--color-lightGray);
  margin: 20px;
  border-radius: 8px;
}

.franchise_tab {
  display: flex;
  align-items: center;
  padding: 20px 20px 0 20px;
  justify-content: flex-end;
  /* border-bottom: 1px solid #ebe8e8; */
}

.franchise_tab .tablinks {
  /* margin-right: 30px; */
  /* padding:10px; */
  margin-right: 10px;
  padding: 20px 10px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  color: #828591;
  cursor: pointer;
}

.franchise_tab .tablinks.active {
  color: var(--color-primary);
  /* color: #fc623d; */
}

.franchise_tab .tablinks.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 3px;
  margin: auto;
  border-radius: 8px;
  background-color: var(--color-primary);
  /* background-color: #fc623d; */
}

.tabcontent {
  /* opacity: 0;
  visibility: hidden; */
  display: none;
  transition: all 0.3s ease-in-out;
}

.tabcontent.active {
  /* opacity: 1; */
  display: block;
  visibility: visible;
}

.franchise_tab_content .tab_title_btn {
  margin-bottom: 25px;
}

.franchise_tab_content .tablinks.btn {
  border-radius: 8px;
  padding: 8px 10px;
}

.franchise_tab_content .tabcontent h3 {
  /* font-size: 22px; */
  font-size: 18px;
  font-weight: 700;
  color: var(--color-default);
}

/* .franchise_tab_content .tabcontent .title_amnt {
  font-size: 16px !important;
  color: #1b1c1f !important;
} */
.franchise_tab_content .tabcontent .title_updated {
  font-size: 12px !important;
  color: var(--color-grayDark) !important;
  font-weight: 500;
  margin-top: -2px;
  display: block;
  padding-left: 30px;
}

.tab_title_btn i {
  margin-right: 5px;
}

.tab_title_btn .edit {
  border: 1px solid var(--color-lightGray) !important;
  background-color: #fff !important;
  color: var(--color-default) !important;
}

.tab_title_btn .edit i {
  color: var(--color-grayDark) !important;
}

/* chart sec */
.franchise_chart_inv {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
}

.franchise_chart,
.franchise_inve,
.meeting_sec {
  border: 1px solid var(--color-lightGray);
  border-radius: 8px;
  overflow: hidden;
  /* width: 32%; */
  padding: 10px;
  height: 100%;
}

.revenue_chart .revenue_amount {
  font-size: 14px !important;
  color: var(--color-grayDark);
}

.revenue_barchart .recharts-text {
  font-size: 12px !important;
  font-weight: 700;
}

/* .meeting_sec {
  width: 30%;
}
.franchise_chart {
  width: 68%;
} */
@media screen and (max-width: 770px) {
  .numb_inv_card {
    padding-right: 12px !important;
  }

  .pie_chart_cont {
    padding-left: 12px !important;
  }

  .meeting_list_grid,
  .table_grid_container {
    padding: 0 !important;
  }

  .table_wrapper {
    overflow: auto;
  }

  /* .meeting_sec {
    width: 49%;
  }
  .franchise_chart {
    width: 49%;
  } */
}

.franchise_chart img {
  width: 100%;
  /* max-height: 250px; */
}

.franchise_inve .franchise_inve_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  text-align: left;
}

.franchise_inve_content:first-child {
  background-color: aliceblue;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 20px !important;
}

.franchise_inve_content .inve_cont_main {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}

.franchise_inve .franchise_inve_content p {
  margin: 0;
}

@media screen and (max-width: 570px) {
  .franchise_chart_inv {
    flex-direction: column;
    padding: 0;
  }

  .franchise_chart,
  .franchise_inve {
    /* width: 100%; */
    margin-bottom: 15px;
  }

  .meeting_sec {
    width: 100%;
  }

  .franchise_chart {
    width: 100%;
  }

  .franchise_chart img {
    width: 100%;
    height: auto;
  }
}

/* meeting section */
.meeting_list {
  margin-bottom: 12px;
}

.meeting_time {
  text-align: center;
  margin-right: 8px;
}

.meeting_time h6 {
  font-size: 12px;
  color: var(--color-default);
}

.meeting_time span {
  display: block;
  font-size: 14px;
  margin-top: -13px;
  color: #857b7b !important;
}

.meeting_content {
  border: 1px solid var(--color-lightGray);
  padding: 15px 12px;
  background-color: #fdfdfd;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}

.meeting_content .icon {
  margin-right: 8px;
}

.meeting_content .icon i svg {
  width: 18px;
  color: var(--color-default);
}

.meeting_content .icon i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: #fff;
  border: 0.5px solid var(--color-lightGray);
  padding: 8px;
  border-radius: 50%;
}

.meeting_content .main_cont h6 {
  font-size: 15px;
}

.meeting_content .main_cont p {
  font-size: 12px;
}

.meeting_content .main_cont p span {
  color: #857b7b !important;
}

/* franchise_amnt_card styling */
.franchise_amnt_dashboardInfo {
  margin-bottom: 15px;
  height: 100%;
}

.franchise_amnt_dashboardInfo .wrap {
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding-top: 20px;
  height: 100%;
}

.franchise_amnt_dashboardInfo .wrap .franchise_inve {
  border: none !important;
}

.franchise_dashboardInfo__title,
.franchise_amnt_dashboardInfo__subInfo {
  color: #6c6c6c;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.franchise_amnt_dashboardInfo span {
  display: block;
}

.c-dashboardInfo__count {
  font-weight: 600;
  font-size: 30px;
  line-height: 64px;
  color: #323c43;
}

.franchise_amnt_dashboardInfo .wrap:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  content: "";
}

.land_card_1 .wrap:after {
  background: linear-gradient(82.59deg, #00c48c 0%, #00a173 100%);
}

.land_card_2 .wrap:after {
  background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
}

.franchise_amnt_dashboardInfo:nth-child(3) .wrap:after {
  background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
}

.franchise_amnt_dashboardInfo:nth-child(4) .wrap:after {
  background: linear-gradient(81.67deg, #ff647c 0%, #1f5dc5 100%);
}

.franchise_amnt_dashboardInfo:nth-child(5) .wrap:after {
  background: linear-gradient(81.67deg, #1fa4c5 0%, #df7ff7 100%);
}

.franchise_dashboardInfo__title svg {
  color: #d7d7d7;
  margin-left: 5px;
}

.MuiSvgIcon-root-19 {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}

@media screen and (max-width: 575px) {
  .franchise_amnt_dashboardInfo {
    max-width: 350px;
    margin: auto;
    margin-bottom: 20px;
  }
}

/* chart filter dropdown */
.select_time_inpt {
  padding-left: 25px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-grayDark);
}

.calender_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  color: var(--color-grayDark);
  font-size: 14px;
}

.numb_inv_card {
  padding-right: 20px
}

.pie_chart_cont {
  padding-left: 20px
}

/* invalid user popup styling */
.invalid_access {
  z-index: 99999;
}
.invli_poppup {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.registration__popup label {
  margin-left: 5px;
  margin-bottom: 5px;
}
.registration__popup .cookiesContent {
  padding: 20px 0 !important;
}
.registration__popup .topSec {
  border-bottom: 1px dashed rgb(189, 187, 187);
  padding-bottom: 15px;
}
.registration__popup .topSec p {
  color: #5d6068;
  margin: 0;
  font-size: 14px;
}
.registration__popup h2 {
  font-size: 22px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 30px;
}
.registration__popup .totalStepsCount {
  font-size: 14px;
  font-weight: 600;
}
/* product multiselect */
.registration__popup .select__menu {
  position: relative !important;
  height: 120px;
}
.registration__popup .react-multiselect .css-1jqq78o-placeholder {
  margin-left: 4px;
}
.registration__popup .css-qbdosj-Input {
  padding-left: 5px !important;
}
.registration__popup .css-12a83d4-MultiValueRemove {
  border-radius: 0 10px 10px 0 !important;
}
.registration__popup .accept {
  background-color: #ed6755;
  border: none;
  border-radius: 5px;
  width: auto !important;
  padding: 7px 15px !important;
  font-size: 16px;
  color: white;
  cursor: pointer;
  box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
}
.registration__popup .skipbtnwrap .skipTxt {
  display: block;
  margin-right: 8px;
  text-decoration: underline;
}
.registration__popup .skipbtnwrap {
  display: flex;
  align-items: end;
}
.registration__popup .btns-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.registration__popup input, .registration__popup select {
  height: 40px !important;
  background-color: #eee;
  color: #7a7a7a;
  border-radius: 8px;
  padding-left: 12px;
  border: none;
}
/* autosuggest select */
.register__overlay {
  background-color: rgba(0, 0, 0, 0.7) !important
}
.completeProfilePopImg {
  width: 160px !important;
}
/* starts multiselect product */
.registration__popup .select__control {
  border-radius: 8px;
  min-height: 40px !important;
  background-color: #eee !important;
}
.registration__popup .css-1p3m7a8-multiValue {
  background-color: var(--color-logoBlue) !important;
  color: white !important;
  border-radius: 15px;
  padding-left: 5px;
}
.registration__popup .css-wsp0cs-MultiValueGeneric {
  color: white !important;
}
/* ends multiselect product */
.registration__popup .css-1fdsijx-ValueContainer {
  padding-top: 0px !important;
}
.registration__popup .css-qbdosj-Input {
  margin: 0px;
}
.registration__popup .cookiesContent {
  width: 600px !important;
  max-height: 6000px;
  overflow-y: auto; 
  border-radius: 5px !important;
}
@media screen and (max-width: 992px) {
  .registration__popup .cookiesContent {
    max-width: 375px !important;
  }
  .completeProfilePopImg {
    width: 120px !important;
  }
  
}
@media screen and (max-width: 435px) {
  .registration__popup .cookiesContent {
    max-width: 310px !important;
  }
}
.invalid_access .cookiesContent {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 20px;
  padding: 20px 20px 30px;
}

.invalid_access button.close {
  width: 30px;
  font-size: 20px;
  color: #c0c5cb;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
}

.invalid_access .popupImg {
  width: 82px;
  margin-bottom: 15px;
}

.invalid_access p {
  margin-bottom: 40px;
  font-size: 18px;
}

.invalid_access button.accept {
  background-color: #ed6755;
  border: none;
  border-radius: 5px;
  width: 200px;
  padding: 14px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
}